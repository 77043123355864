import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import EditIcon from '../../../../../assets/icons/icons8-edit.svg';
import { poundFormatter, getSerialNumberLabel } from '../../../../../utils';
import ExpandRight from '../../../../../components/ExpandRight';
import { IOrderItem } from '../../../../../interfaces/IOrder';
import { IGenericEntry } from '../../../../../interfaces/IGenericSection';
import PriorityModal from '../../PriorityModal';

const OrderItemList: React.SFC<{
  item: IOrderItem;
}> = ({ item }) => {
  const history = useHistory();
  const [priorityOpen, setPriorityOpen] = useState(false);
  const [priorityValue, setPriorityValue] = useState(item.priority || 0);

  const redirectProduct = () => {
    history.push({
      pathname: '/inventory-management',
      search: `?productCode=${item.productCode}`
    });
  };

  return (
    <>
      <ExpandRight
        key={item.id}
        title={`${item.productCode} - ${item.description} (${item.productType})`}
        subtitle={[
          {
            code: 'Status',
            value: item.statusDesc || item.status
          },
          {
            code: 'Trade-in dependency',
            value: item.tradeInDetails
              ? item.tradeInDetails.tradeInDependency.toString()
              : ''
          },
          {
            code: 'Priority',
            value: priorityValue.toString(),
            iconType: 'edit',
            icon: EditIcon,
            action: !item.priorityChangeable
              ? null
              : () => {
                  setPriorityOpen(true);
                }
          }
        ].filter((entry: IGenericEntry) => entry.value !== '')}
        titleTag={
          item.isAddOn ? '(Add-on) ' : item.isOverride ? '(Bag-override) ' : ''
        }
        description={[
          {
            code: getSerialNumberLabel(item.productType),
            value: item.serialIMEI || ''
          },
          {
            code: 'Serial MAC',
            value: item.serialMAC || ''
          },
          {
            code: 'Serial EID',
            value: item.serialEID || ''
          },
          {
            code: 'Fault Code',
            value: item.faultCode || ''
          },
          {
            code: 'Fault Code Name',
            value: item.faultCodeDesc || ''
          },
          {
            code: 'Repair Flag',
            value: item.repairFlag || ''
          }
        ].filter((entry: IGenericEntry) => entry.value !== '')}
        subinventoryEntries={[
          {
            code: 'Unit selling price',
            value: poundFormatter(item.unitSellingPrice)
          },
          {
            code: 'Line net selling price',
            value: poundFormatter(item.netSellingPrice)
          },
          {
            code: 'Line gross selling price',
            value: poundFormatter(item.grossSellingPrice)
          },
          {
            code: 'VAT amount',
            value: poundFormatter(item.vatAmount)
          },
          {
            code: 'VAT code',
            value: item.vatCode
          },
          {
            code: 'Priority code',
            value: item.shippingDependency
          },
          {
            code: 'Upgrade indicator',
            value: `(${item.upgradeIndicator}) ${item.upgradeIndicatorName}`
          }
        ]}
        redirectLink={redirectProduct}
      />
      {item.priorityChangeable && (
        <PriorityModal
          reservationFullId={item.reservationFullId}
          priority={item.priority}
          productCode={item.productCode}
          openState={priorityOpen}
          handleClose={() => {
            setPriorityOpen(false);
          }}
          setUpdatedPriority={(newPriority: any) => {
            setPriorityValue(newPriority);
          }}
        />
      )}
    </>
  );
};

export default OrderItemList;
