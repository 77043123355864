import React from 'react';
import { Container, MenuItem, Select } from '@material-ui/core';

import StyledSearchMenu from './styles';
import ControlledInput from '../../../../components/ControlledInput';
import CustomButton from '../../../../components/CustomButton';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import Label from '../../../../components/Label';
import StyledFormControl from '../../../../components/ControlledSelect/styles';

interface ISearchMenuProps {
  stockPot: string;
  setStockPot: CallableFunction;
  subinventories: string[];
  status: string;
  setStatus: CallableFunction;
  statuses: string[];
  snapshotDate: Date | null;
  setSnapshotDate: CallableFunction;
  handleSubmit: any;
  productCode: string;
  setProductCode: CallableFunction;
}

const SearchMenu: React.FC<ISearchMenuProps> = ({
  stockPot,
  setStockPot,
  subinventories,
  status,
  setStatus,
  statuses,
  snapshotDate,
  setSnapshotDate,
  handleSubmit,
  productCode,
  setProductCode
}) => {
  const clearSearchOptions = () => {
    setProductCode('');
    setStockPot('');
    setStatus('');
    setSnapshotDate(null);
  };

  return (
    <StyledSearchMenu>
      <Container fixed>
        <div className='search-menu-row row-header'>
          <h2>Search Orders</h2>
        </div>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <div className='search-menu-row'>
            <ControlledInput
              id='product-code'
              placeholder='Type item SKU here'
              value={productCode}
              handleChange={(event: any) => setProductCode(event.target.value)}
              label='Item SKU'
              type='text'
              classes='label--w-25'
            />
            <Label
              id='sub-inventory'
              control={
                <StyledFormControl variant={'outlined' as any}>
                  <Select
                    data-testid='select-testid'
                    displayEmpty
                    value={stockPot}
                    onChange={(event: any) => setStockPot(event.target.value)}
                  >
                    <MenuItem key='placeholder' value=''>
                      Select sub-inventory
                    </MenuItem>
                    {subinventories.map((option: string) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              }
              label='Sub-inventory'
              classes='label--w-25'
            />
            <Label
              id='status'
              control={
                <StyledFormControl variant={'outlined' as any}>
                  <Select
                    data-testid='select-testid'
                    displayEmpty
                    value={status}
                    onChange={(event: any) => setStatus(event.target.value)}
                  >
                    <MenuItem key='placeholder' value=''>
                      Select status
                    </MenuItem>
                    {statuses.map((option: string) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              }
              label='Status'
              classes='label--w-25'
            />
            <CustomDatePicker
              id='start-date'
              value={snapshotDate}
              handleChange={(event: any) => setSnapshotDate(event)}
              label='Snapshot date'
              classes='label--w-25'
            />
          </div>
          <hr />
          <div className='search-menu-row'>
            <div className='search-btn-wrapper'>
              <CustomButton
                type='button'
                classes='btn--w-200-px btn--light-grey clear-btn'
                title='Clear'
                handleClick={() => clearSearchOptions()}
              />
            </div>
            <CustomButton
              type='submit'
              classes='btn--w-200-px'
              title='Search'
            />
          </div>
        </form>
      </Container>
    </StyledSearchMenu>
  );
};

export default SearchMenu;
