import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IconButton } from '@material-ui/core';

import environment from '../../../../environment';
import DisableIcon from '../../../../assets/icons/disable.svg';
import EnableIcon from '../../../../assets/icons/enable.svg';

// eslint-disable-next-line
export default ({ data, node }: any): JSX.Element => {
  const buttonClicked = () => {
    axios
      .post(
        `${environment.apiPath}enableScheduledReport`,
        {
          scheduledReportId: data.scheduledReportId,
          enabled: !data.enabled
        },
        { ...environment.params }
      )
      .then(
        (res) => {
          const status = res.data.enabled ? 'enabled' : 'disabled';
          node.setData({ ...data, enabled: res.data.enabled });
          toast.success(`Scheduled report successfully ${status}`);
        },
        (err) => {
          toast.error(err.message);
        }
      );
  };

  return (
    <IconButton tabIndex={0} aria-label='Disable' onClick={buttonClicked}>
      {data.enabled ? (
        <img src={DisableIcon} alt='Disable' />
      ) : (
        <img src={EnableIcon} alt='Enable' />
      )}
    </IconButton>
  );
};
