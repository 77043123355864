import React, { useEffect, useState } from 'react';
import { Chip, Container, MenuItem, Select } from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Alert } from '@material-ui/lab';

import StyledSearchMenu from './styles';
import ControlledInput from '../../../../components/ControlledInput';
import CustomButton from '../../../../components/CustomButton';
import { IGenericEntry } from '../../../../interfaces/IGenericSection';
import ControlledSelect from '../../../../components/ControlledSelect';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import Label from '../../../../components/Label';
import StyledFormControl from '../../../../components/ControlledSelect/styles';
import SearchQueryModal from '../SearchQueryModal';
import environment from '../../../../environment';
import { IInventoryItem } from '../../../../interfaces/IInventoryItem';

interface ISearchMenuProps {
  productCodes: string[];
  setProductCodes: CallableFunction;
  productDescription: string;
  setProductDescription: CallableFunction;
  stockPot: string;
  setStockPot: CallableFunction;
  subinventories: string[];
  statusOperator: IGenericEntry;
  setStatusOperator: CallableFunction;
  status: string;
  setStatus: CallableFunction;
  statuses: string[];
  createDateOption: IGenericEntry;
  setCreateDateOption: CallableFunction;
  createDateOptions: IGenericEntry[];
  startDate: Date | null;
  setStartDate: CallableFunction;
  endDate: Date | null;
  setEndDate: CallableFunction;
  statusOperators: IGenericEntry[];
  handleSubmit: any;
  productCode: string;
  setProductCode: CallableFunction;
}

const SearchMenu: React.FC<ISearchMenuProps> = ({
  productCodes,
  setProductCodes,
  productDescription,
  setProductDescription,
  stockPot,
  setStockPot,
  subinventories,
  statusOperator,
  setStatusOperator,
  statusOperators,
  status,
  setStatus,
  statuses,
  createDateOption,
  setCreateDateOption,
  createDateOptions,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleSubmit,
  productCode,
  setProductCode
}) => {
  const [selectedQuery, setSelectedQuery] = useState('');
  const [reportQueries, setReportQueries] = useState([]);
  const [errorCodes, setErrorCodes] = useState<string[]>([]);

  const addProductCode = () => {
    const newProducts = productCode.split(',');
    axios
      .get(`${environment.apiPath}product?productCode=${productCode}`, {
        ...environment.params
      })
      .then(
        (res) => {
          const codes = res.data.items.map(
            (product: IInventoryItem) => product.productCode
          );
          const errCodes = newProducts.filter(
            (product: string) => !codes.includes(product.replace(/\s/g, ''))
          );
          setProductCodes([
            ...productCodes,
            ...codes.filter(
              (product: string) => !productCodes.includes(product)
            )
          ]);
          setErrorCodes(errCodes);
          setProductCode('');
        },
        (err) => toast.error(err.message)
      );
  };

  const removeProductCode = (product: string) => {
    setProductCodes(productCodes.filter((item) => item !== product));
  };

  const handleQuerySelection = (event: any) => {
    const searchOptions = event.target.value.queryData;
    const operator = statusOperators.find(
      (op: IGenericEntry) => op.code === searchOptions.statusOperator.code
    );
    const createDateStart = searchOptions.createDateStart
      ? searchOptions.createDateStart
      : null;
    const createDateEnd = searchOptions.createDateEnd
      ? searchOptions.createDateEnd
      : null;

    setSelectedQuery(event.target.value);
    setProductCodes(searchOptions.productCodes);
    setProductDescription(searchOptions.descriptions[0]);
    setStockPot(searchOptions.stockPot);
    setStatusOperator(operator === undefined ? statusOperators[0] : operator);
    setStatus(searchOptions.status);
    setStartDate(createDateStart);
    setEndDate(createDateEnd);
  };

  const clearSearchOptions = () => {
    setSelectedQuery('');
    setProductCodes([]);
    setErrorCodes([]);
    setProductDescription('');
    setStockPot('');
    setStatusOperator(statusOperators[0]);
    setStatus('');
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    axios
      .get(`${environment.apiPath}reportQuery`, {
        ...environment.params
      })
      .then(
        (res) => setReportQueries(res.data),
        (err) => toast.error(err.message)
      );
  }, []);

  return (
    <StyledSearchMenu>
      <Container fixed>
        <div className='search-menu-row row-header'>
          <h2>Search Orders</h2>
          <Label
            id='search-query'
            control={
              <StyledFormControl variant={'outlined' as any}>
                <Select
                  data-testid='select-query-testid'
                  displayEmpty
                  value={selectedQuery}
                  onChange={handleQuerySelection}
                >
                  <MenuItem key='placeholder' value='' disabled>
                    Select saved search query
                  </MenuItem>
                  {reportQueries.map((query: any) => (
                    <MenuItem key={query.queryId} value={query}>
                      {query.queryName}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            }
            label='Saved search query'
            classes='label--w-30'
          />
        </div>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <div className='search-menu-row'>
            <div className='product-code-search'>
              <div className='product-code-search__input'>
                <ControlledInput
                  id='product-code'
                  placeholder='Type item SKU here'
                  value={productCode}
                  handleChange={(event: any) =>
                    setProductCode(event.target.value)
                  }
                  label='Item SKU'
                  type='text'
                  classes='label--w-100'
                />
                <CustomButton
                  type='button'
                  classes='btn--w-10 btn--blue'
                  title='Add'
                  handleClick={() => addProductCode()}
                />
              </div>
              {errorCodes.length > 0 && (
                <Alert severity='error'>
                  Product(s) {errorCodes.join(', ')} not found
                </Alert>
              )}
              {productCodes.map((product: string) => (
                <Chip
                  key={product}
                  label={product}
                  onDelete={() => removeProductCode(product)}
                />
              ))}
            </div>
            <ControlledInput
              id='product-description'
              placeholder='Type item description here'
              value={productDescription}
              handleChange={(event: any) =>
                setProductDescription(event.target.value)
              }
              label='Item description'
              type='text'
              classes='label--w-45'
            />
          </div>
          <hr />
          <div className='search-menu-row'>
            <Label
              id='sub-inventory'
              control={
                <StyledFormControl variant={'outlined' as any}>
                  <Select
                    data-testid='select-testid'
                    displayEmpty
                    value={stockPot}
                    onChange={(event: any) => setStockPot(event.target.value)}
                  >
                    <MenuItem key='placeholder' value='' disabled>
                      Select sub-inventory
                    </MenuItem>
                    {subinventories.map((option: string) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              }
              label='Sub-inventory'
              classes='label--w-30'
            />
            <ControlledSelect
              id='status-operator'
              value={statusOperator}
              handleChange={(event: any) =>
                setStatusOperator(event.target.value)
              }
              label='Status'
              options={statusOperators}
              classes='label--w-30'
            />
            <Label
              id='status'
              control={
                <StyledFormControl variant={'outlined' as any}>
                  <Select
                    data-testid='select-testid'
                    displayEmpty
                    value={status}
                    onChange={(event: any) => setStatus(event.target.value)}
                  >
                    <MenuItem key='placeholder' value='' disabled>
                      Select status
                    </MenuItem>
                    {statuses.map((option: string) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              }
              label=''
              classes='label--w-30 mt-16'
            />
          </div>
          <hr />
          <div className='search-menu-row'>
            <ControlledSelect
              id='create-date-option'
              value={createDateOption}
              handleChange={(event: any) =>
                setCreateDateOption(event.target.value)
              }
              label='Create date'
              options={createDateOptions}
              classes='label--w-30'
            />
            <CustomDatePicker
              id='start-date'
              value={startDate}
              handleChange={(event: any) => setStartDate(event)}
              label='Start date'
              classes='label--w-30'
            />
            <CustomDatePicker
              id='end-date'
              value={endDate}
              handleChange={(event: any) => setEndDate(event)}
              label='End date'
              classes='label--w-30'
            />
          </div>
          <hr />
          <div className='search-menu-row'>
            <div className='search-btn-wrapper'>
              <CustomButton
                type='button'
                classes='btn--w-200-px btn--light-grey clear-btn'
                title='Clear'
                handleClick={() => clearSearchOptions()}
              />
              <SearchQueryModal
                productCodes={productCodes}
                productDescription={productDescription}
                stockPot={stockPot}
                statusOperator={statusOperator}
                status={status}
                startDate={startDate}
                endDate={endDate}
                reportQueries={reportQueries}
                setReportQueries={setReportQueries}
              />
            </div>
            <CustomButton
              type='submit'
              classes='btn--w-200-px'
              title='Search'
            />
          </div>
        </form>
      </Container>
    </StyledSearchMenu>
  );
};

export default SearchMenu;
