import React, { useState, SyntheticEvent, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { toast } from 'react-toastify';
import axios from 'axios';

import StyledSearchBar from './styles';
import CustomButton from '../CustomButton';
import { IGenericEntry } from '../../interfaces/IGenericSection';
import environment from '../../environment';
import LoadingScreen from '../LoadigScreen';
import ControlledInput from '../ControlledInput';
import ControlledSelect from '../ControlledSelect';

export interface ISearchBarProps {
  searchOptions: IGenericEntry[];
  buttonTitle: string;
  path: string;
  handleSuccess: CallableFunction;
  clearData: CallableFunction;
  history: any;
  location: string;
}

const SearchBar: React.FC<ISearchBarProps> = ({
  searchOptions,
  buttonTitle,
  path,
  handleSuccess,
  clearData,
  history,
  location
}) => {
  const [searchOption, setSearchOption] = useState<IGenericEntry>(
    searchOptions[0]
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const getElements = (option: string, value: string) => {
    axios
      .get(`${environment.apiPath}${path}?${option}=${value}`, {
        ...environment.params
      })
      .then(
        (res) => {
          handleSuccess(res.data);
          setLoading(false);
        },
        (err) => {
          toast.error(err.message);
          setLoading(false);
        }
      );
  };

  useEffect(() => {
    if (history.location.search) {
      const searchElements = history.location.search.split(/[?&=]/);
      const option = searchOptions.find(
        (opt: IGenericEntry) => opt.code === searchElements[1]
      );

      if (option) {
        setSearchOption(option);
      }
      setSearchValue(searchElements[2]);
      clearData();
      setLoading(true);

      getElements(searchElements[1], searchElements[2]);
    }
  }, [history.location]);

  const handleSearchOptionChange = (event: any) => {
    setSearchOption(event.target.value);
  };

  const handleSearchValueChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    history.push({
      pathname: location,
      search: `?${searchOption.code}=${searchValue}`
    });
  };

  return (
    <>
      <StyledSearchBar>
        <Container fixed>
          <form noValidate autoComplete='off' onSubmit={handleSubmit}>
            <ControlledSelect
              id='search-option'
              value={searchOption}
              handleChange={handleSearchOptionChange}
              label='Search option'
              options={searchOptions}
              classes='label--w-20'
            />
            <ControlledInput
              id='search-value'
              placeholder={`Type ${searchOption.value.toLowerCase()} here`}
              value={searchValue}
              handleChange={handleSearchValueChange}
              label={searchOption.value}
              type='text'
              classes='label--w-60'
            />
            <CustomButton
              type='submit'
              disabled={!searchValue}
              classes='btn--w-20'
              title={buttonTitle}
            />
          </form>
        </Container>
      </StyledSearchBar>
      {loading && <LoadingScreen />}
    </>
  );
};

export default SearchBar;
