import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import StyledDateTimePicker from './styles';
import Label from '../Label';

interface ICustomDatePickerProps {
  id: string;
  value: Date | null;
  handleChange: any;
  label: string;
  classes?: string;
  disabled?: boolean;
  minDate?: Date | null;
  minDateMessage?: string;
  onError?: any;
}

const CustomDatePicker: React.SFC<ICustomDatePickerProps> = ({
  id,
  value,
  handleChange,
  label,
  classes,
  disabled,
  minDate,
  minDateMessage,
  onError
}) => (
  <Label
    id={id}
    control={
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <StyledDateTimePicker
          variant='inline'
          value={value}
          format='dd/MM/yyyy HH:mm'
          onChange={handleChange}
          placeholder='dd/mm/yyyy HH:MM'
          disabled={disabled}
          minDate={minDate}
          minDateMessage={minDateMessage}
          onError={onError}
        />
      </MuiPickersUtilsProvider>
    }
    label={label}
    classes={classes}
  />
);

CustomDatePicker.defaultProps = {
  classes: '',
  disabled: false,
  minDate: undefined,
  minDateMessage: '',
  onError: null
};

export default CustomDatePicker;
