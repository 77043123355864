import React from 'react';

import { IOrderItem } from '../../../../interfaces/IOrder';
import OrderItem from './OrderItem';

const OrderItemList: React.SFC<{
  items: IOrderItem[];
}> = ({ items }) => (
  <>
    {items.map((item: IOrderItem) => (
      <OrderItem item={item} key={item.id} />
    ))}
  </>
);

export default OrderItemList;
