import { CognitoUser } from '@aws-amplify/auth';
import { Auth, Hub } from 'aws-amplify';

import User from '../../models/user';
import { IUserPermissions } from '../../interfaces/IAuthState';

export const getUser = async (): Promise<any> => {
  const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();

  return new User(cognitoUser);
};

export function getUserPermissions(userRole: string): IUserPermissions {
  switch (userRole) {
    case 'APP-VFGroup-CavendishAdmin':
      return {
        viewOrderReports: true,
        changeStockLevel: true,
        viewConfigInfo: true,
        viewCustomerInfo: true
      };
    case 'APP-VFGroup-CavendishTerminals':
      return {
        viewOrderReports: false,
        changeStockLevel: true,
        viewConfigInfo: true,
        viewCustomerInfo: false
      };
    case 'APP-VFGroup-CavendishAgent':
      return {
        viewOrderReports: false,
        changeStockLevel: false,
        viewConfigInfo: false,
        viewCustomerInfo: false
      };
    case 'APP-VFGroup-CavendishAgentManager':
      return {
        viewOrderReports: false,
        changeStockLevel: false,
        viewConfigInfo: false,
        viewCustomerInfo: true
      };
    case 'APP-VFGroup-CavendishSupplyChain':
      return {
        viewOrderReports: true,
        changeStockLevel: false,
        viewConfigInfo: true,
        viewCustomerInfo: false
      };
    default:
      return {
        viewOrderReports: false,
        changeStockLevel: false,
        viewConfigInfo: false,
        viewCustomerInfo: false
      };
  }
}

export default async function mountHubListener(
  setState: CallableFunction
): Promise<void> {
  Hub.listen('auth', async ({ payload: { event, data } }) => {
    switch (event) {
      case 'cognitoHostedUI': {
        const user = await getUser();
        setState({
          authState: 'signedIn',
          user,
          permissions: getUserPermissions(user?.userData.attributes.profile)
        });
        break;
      }
      case 'cognitoHostedUI_failure':
        setState({
          authState: 'signedOut',
          user: undefined,
          error: data
        });
        break;
      default:
        break;
    }
  });
}
