import styled from 'styled-components';

export default styled.div`
  background-image: url(${(props: { background: string }) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  height: 220px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 64px;
    font-family: Vodafone Lt;
    color: ${(props) => props.theme.colors.white};
    text-align: center;
  }

  &.h-320 {
    height: 320px;

    h1 {
      text-align: left;
    }
  }
`;
