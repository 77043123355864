import React, { useState, SyntheticEvent } from 'react';
import {
  DialogTitle,
  DialogContent,
  Container,
  IconButton
} from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';

import CustomButton from '../../../../components/CustomButton';
import cavendishTheme from '../../../../assets/theme';
import CloseIcon from '../../../../assets/icons/close.svg';
import StyledDialog from './styles';
import ControlledInput from '../../../../components/ControlledInput';
import environment from '../../../../environment';
import LoadingScreen from '../../../../components/LoadigScreen';
import { IDeliveryGroup, IOrderItem } from '../../../../interfaces/IOrder';
import { getSerialNumberLabel } from '../../../../utils';

interface IDispatchProps {
  orderID: string;
  deliveryGroup: IDeliveryGroup;
  setDeliveryGroup: CallableFunction;
}

const DispatchModal: React.FC<IDispatchProps> = ({
  orderID,
  deliveryGroup,
  setDeliveryGroup
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSuccess = (group: IDeliveryGroup) => {
    setOpen(false);
    setLoading(false);
    setDeliveryGroup(group);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(
        `${environment.apiPath}dispatchDeliveryGroup`,
        {
          orderID,
          deliveryGroup
        },
        { ...environment.params }
      )
      .then(
        (res) => handleSuccess(res.data),
        (err) => {
          if (err.response) {
            toast.error(err.response.data.error);
          } else {
            toast.error(err.message);
          }
          setLoading(false);
          setOpen(false);
        }
      );
  };

  const handleIMEIChange = (item: IOrderItem, serialIMEI: string) => {
    console.log(item);
    setDeliveryGroup({
      ...deliveryGroup,
      items: deliveryGroup.items.map((itm: IOrderItem) =>
        itm.id === item.id
          ? {
              ...itm,
              serialIMEI
            }
          : itm
      )
    });
  };

  const handleEIDChange = (item: IOrderItem, serialEID: string) => {
    setDeliveryGroup({
      ...deliveryGroup,
      items: deliveryGroup.items.map((itm: IOrderItem) =>
        itm.id === item.id
          ? {
              ...itm,
              serialEID
            }
          : itm
      )
    });
  };

  return (
    <>
      <CustomButton
        title='Dispatch'
        handleClick={() => setOpen(true)}
        classes='btn--blue'
      />
      <StyledDialog
        fullScreen
        open={open}
        aria-labelledby='dialog-title'
        PaperProps={{
          style: {
            backgroundColor: cavendishTheme.colors.black
          }
        }}
      >
        <Container fixed className='close-btn'>
          <IconButton
            tabIndex={0}
            aria-label='Close'
            onClick={() => {
              setOpen(false);
            }}
            className='close'
          >
            <img src={CloseIcon} alt='Close' />
          </IconButton>
        </Container>

        <DialogTitle id='dialog-title'>{orderID}</DialogTitle>
        <h3>
          {deliveryGroup.number} - {deliveryGroup.details.dispatchMethodName},
          {deliveryGroup.details.serviceName}
        </h3>
        <hr />

        <DialogContent className='dialog-content'>
          <form
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
            className='dialog-content__form'
          >
            {deliveryGroup.items
              .filter(
                (item: IOrderItem) =>
                  item.status === 'DISPATCH_INITIATED' && item.serialControlled
              )
              .map((item: IOrderItem) => (
                <>
                  <h4>
                    {item.productCode} - {item.description} ({item.productType})
                  </h4>
                  <div className='column'>
                    <ControlledInput
                      id='IMEI'
                      label={getSerialNumberLabel(item.productType)}
                      type='string'
                      value={item.serialIMEI}
                      placeholder={`Type ${getSerialNumberLabel(
                        item.productType
                      )} here`}
                      handleChange={(event: any) =>
                        handleIMEIChange(item, event.target.value)
                      }
                    />
                    {item.eSIMDeviceIndicator && (
                      <ControlledInput
                        id='EID'
                        label='EID'
                        type='string'
                        value={item.serialEID}
                        placeholder='Type EID here'
                        handleChange={(event: any) =>
                          handleEIDChange(item, event.target.value)
                        }
                      />
                    )}
                    <hr />
                  </div>
                </>
              ))}
            {deliveryGroup.details.dispatchMethodName === 'DPD' && (
              <ControlledInput
                id='consignmentNumber'
                label='Consignment number'
                type='string'
                value={deliveryGroup.consignmentNumber}
                placeholder='Type Consignment number here'
                handleChange={(event: any) =>
                  setDeliveryGroup({
                    ...deliveryGroup,
                    consignmentNumber: event.target.value
                  })
                }
              />
            )}
            <CustomButton title='Dispatch' type='submit' classes='btn--w-100' />
          </form>
        </DialogContent>
        {loading && <LoadingScreen />}
      </StyledDialog>
    </>
  );
};

export default DispatchModal;
