import React, { useState, SyntheticEvent } from 'react';
import {
  DialogTitle,
  DialogContent,
  Container,
  IconButton
} from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';

import CustomButton from '../../../../components/CustomButton';
import cavendishTheme from '../../../../assets/theme';
import CloseIcon from '../../../../assets/icons/close.svg';
import StyledDialog from './styles';
import ControlledInput from '../../../../components/ControlledInput';
import environment from '../../../../environment';
import LoadingScreen from '../../../../components/LoadigScreen';
import { ISubInventory } from '../../../../interfaces/IInventoryItem';

interface ISubInventoryProps {
  productCode: string;
  productDescription: string;
  channel: string;
  subinventory: ISubInventory;
}

const PreorderReleaseModal: React.FC<ISubInventoryProps> = ({
  productCode,
  productDescription,
  channel,
  subinventory
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [releaseQuantity, setReleaseQuantity] = useState(
    subinventory.stockOnHand - subinventory.allocated
  );

  const handleSuccess = (totalAllocated: number) => {
    setReleaseQuantity(subinventory.stockOnHand - totalAllocated);
    setOpen(false);
    setLoading(false);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);

    axios
      .post(
        `${environment.apiPath}releasePreorders`,
        {
          productCode,
          subinventory: subinventory.subinventory,
          releaseQuantity
        },
        { ...environment.params }
      )
      .then(
        (response) => handleSuccess(response.data.totalAllocated),
        (err) => {
          toast.error(err.message);
          setLoading(false);
          setOpen(false);
        }
      );
  };

  return (
    <>
      <CustomButton
        title='Release preorders'
        classes='btn--small'
        handleClick={() => setOpen(true)}
      />

      <StyledDialog
        fullScreen
        open={open}
        aria-labelledby='dialog-title'
        PaperProps={{
          style: {
            backgroundColor: cavendishTheme.colors.black
          }
        }}
      >
        <Container fixed className='close-btn'>
          <IconButton
            tabIndex={0}
            aria-label='Close'
            onClick={() => setOpen(false)}
            className='close'
          >
            <img src={CloseIcon} alt='Close' />
          </IconButton>
        </Container>

        <DialogTitle id='dialog-title'>{productCode}</DialogTitle>
        <h3>{productDescription}</h3>
        <div className='dialog-subtitle'>
          <p>
            {channel} - {subinventory.subinventory}
          </p>
        </div>

        <DialogContent className='dialog-content'>
          <form
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
            className='dialog-content__form'
          >
            <ControlledInput
              id='releaseQuantity'
              label='Release quantity'
              type='number'
              value={releaseQuantity}
              placeholder='Type release quantity here'
              handleChange={(event: any) =>
                setReleaseQuantity(event.target.value)
              }
            />
            <CustomButton
              title='Release preorders'
              type='submit'
              classes='btn--w-100'
            />
          </form>
        </DialogContent>
        {loading && <LoadingScreen />}
      </StyledDialog>
    </>
  );
};

export default PreorderReleaseModal;
