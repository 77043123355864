import React from 'react';

import StyledTextField from './styles';
import Label from '../Label';

interface IControlledInputProps {
  id: string;
  type: string;
  label: string;
  value: any;
  handleChange: any;
  placeholder?: string;
  classes?: string;
  disabled?: boolean;
}

const ControlledInput: React.SFC<IControlledInputProps> = ({
  id,
  type,
  label,
  value,
  placeholder,
  handleChange,
  classes,
  disabled
}) => (
  <Label
    id={id}
    control={
      <StyledTextField
        data-testid='textfield-testid'
        variant={'outlined' as any}
        type={type}
        placeholder={placeholder}
        inputProps={{
          id,
          shrink: 'true',
          'data-testid': 'textfield-input-testid'
        }}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
    }
    label={label}
    classes={classes}
  />
);

ControlledInput.defaultProps = {
  placeholder: '',
  classes: '',
  disabled: false
};

export default ControlledInput;
