import React from 'react';

import { boolFormatter } from '../../../../utils';
import InfoIcon from '../../../../assets/icons/info.svg';
import StockIcon from '../../../../assets/icons/stock.svg';
import ClassificationIcon from '../../../../assets/icons/item-classification.svg';
import ProductIcon from '../../../../assets/icons/product.svg';
import SalesIcon from '../../../../assets/icons/sales.svg';
import GenericSection from '../../../../components/GenericSection';
import { IInventoryItem } from '../../../../interfaces/IInventoryItem';

const ItemSummary: React.SFC<{ item: IInventoryItem }> = ({ item }) => (
  <div className='row'>
    <div className='column'>
      <GenericSection
        title={{
          img: InfoIcon,
          altText: 'Info',
          title: 'Item details'
        }}
        entries={[
          {
            code: 'Description',
            value: item.shortDescription
          },
          {
            code: 'Part of BOM',
            value: boolFormatter(item.partBom)
          },
          {
            code: 'Serial type',
            value: boolFormatter(item.serialType)
          },
          {
            code: 'Serial controlled',
            value: boolFormatter(item.serialControlled)
          },
          {
            code: 'Virtual product',
            value: boolFormatter(item.isVirtual)
          },
          {
            code: 'Manufacturer warranty',
            value: item.warranty
          },
          {
            code: 'eSIM capable',
            value: boolFormatter(item.isRefurb)
          }
        ]}
      />
      <GenericSection
        title={{
          img: ClassificationIcon,
          altText: 'Classification',
          title: 'Item classification'
        }}
        entries={[
          {
            code: 'Generic',
            value: boolFormatter(item.isGeneric)
          },
          {
            code: 'Bailment',
            value: boolFormatter(item.isBailment)
          }
        ]}
      />
    </div>

    <div className='column'>
      <GenericSection
        title={{
          img: StockIcon,
          altText: 'Stock',
          title: 'Stock'
        }}
        entries={[
          {
            code: 'Purchasable',
            value: boolFormatter(item.purchasableFlag)
          },
          {
            code: 'Pickable',
            value: boolFormatter(item.pickable)
          },
          {
            code: 'Planned',
            value: boolFormatter(item.planned)
          }
        ]}
      />
      <GenericSection
        title={{
          img: ProductIcon,
          altText: 'Product',
          title: 'Measurements'
        }}
        entries={[
          {
            code: 'Primary unit of measure',
            value: item.primaryUom
          }
        ]}
      />
      <GenericSection
        title={{
          img: SalesIcon,
          altText: 'Sales',
          title: 'Sales'
        }}
        entries={[
          {
            code: 'VAT code',
            value: item.vatCode
          }
        ]}
      />
    </div>
  </div>
);

export default ItemSummary;
