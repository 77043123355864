import React from 'react';

import { dateHourFormatter, addressFormatter } from '../../../../utils';
import GenericEntry from '../../../../components/GenericSection/components/GenericEntry';
import { IDeliveryGroupDetails } from '../../../../interfaces/IOrder';
import StyledLink from './styles';
import HiddenEntry from '../../../../components/GenericSection/components/HiddenEntry';

const DeliveryDetails: React.SFC<{
  details: IDeliveryGroupDetails;
  consignmentNumber?: string;
  pickNumber?: string;
  expectedDeliveryDate?: string;
  inboundSlid?: string;
  outboundSlid?: string;
  redirectPick: CallableFunction;
  returnReferenceCode?: string;
  isInbound?: boolean;
  isTradeIn?: boolean;
  hideCustomerInfo: boolean;
}> = ({
  details,
  consignmentNumber,
  pickNumber,
  expectedDeliveryDate,
  inboundSlid,
  outboundSlid,
  redirectPick,
  returnReferenceCode,
  isInbound,
  isTradeIn,
  hideCustomerInfo
}) => (
  <>
    <h5 className='inner-accordion__details__subtitle '>Delivery details</h5>
    <div className='row'>
      <div className='column'>
        {hideCustomerInfo ? (
          <HiddenEntry code='Contact' />
        ) : (
          <GenericEntry code='Contact' value={details.contact} />
        )}
        {hideCustomerInfo ? (
          <HiddenEntry code='Address' />
        ) : (
          <GenericEntry
            code='Address'
            value={addressFormatter(details.address)}
          />
        )}

        <GenericEntry
          code='Requested delivery date'
          value={dateHourFormatter(details.scheduledDeliveryDate)}
        />
        <GenericEntry
          code='Predicted delivery date'
          value={dateHourFormatter(details.predictedDeliveryDate)}
        />
        <GenericEntry
          code='Consignment number'
          value={consignmentNumber || '-'}
        />
        {pickNumber && (
          <StyledLink
            type='button'
            aria-label='Search orders by pick number'
            onClick={() => redirectPick()}
          >
            <GenericEntry code='Pick number' value={pickNumber || '-'} />
          </StyledLink>
        )}
        {returnReferenceCode && (isInbound || isTradeIn) && (
          <GenericEntry
            code='Return reference code'
            value={returnReferenceCode || '-'}
          />
        )}
      </div>

      <div className='column'>
        <GenericEntry
          code='Expected delivery date'
          value={dateHourFormatter(expectedDeliveryDate || '')}
        />
        <GenericEntry
          code='Method'
          value={`(${details.dispatchMethod}) ${
            details.dispatchMethodName ? details.dispatchMethodName : '-'
          }`}
          entryTag={details.dispatchMethodOverride ? '(Override) ' : ''}
        />
        <GenericEntry
          code='Service'
          value={`(${details.service}) ${
            details.serviceName ? details.serviceName : '-'
          }`}
          entryTag={details.serviceOverride ? '(Override) ' : ''}
        />
        <GenericEntry code='Inbound SLID' value={inboundSlid || '-'} />
        <GenericEntry code='Outbound SLID' value={outboundSlid || '-'} />
      </div>
    </div>
  </>
);

DeliveryDetails.defaultProps = {
  consignmentNumber: '',
  pickNumber: '',
  expectedDeliveryDate: '',
  inboundSlid: '',
  outboundSlid: '',
  returnReferenceCode: '',
  isInbound: false,
  isTradeIn: false
};

export default DeliveryDetails;
