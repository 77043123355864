import React from 'react';

import { IconButton } from '@material-ui/core';
import StyledHiddenEntry from './styles';
import { IHiddenEntry } from '../../../../interfaces/IGenericSection';

const HiddenEntry: React.SFC<IHiddenEntry> = ({
  code,
  action,
  icon,
  entryTag
}) => (
  <StyledHiddenEntry>
    {code}: {entryTag && <span className='tag'>{entryTag}</span>}
    <span>*****</span>
    {action && (
      <IconButton tabIndex={0} onClick={action}>
        <img src={icon} alt='' width='16' height='16' />
      </IconButton>
    )}
  </StyledHiddenEntry>
);

export default HiddenEntry;
