import styled from 'styled-components';

export default styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.silverGrey};
  margin: 30px 0;

  &:nth-child(even) {
    border-left: 5px solid ${(props) => props.theme.colors.black};
  }

  &:nth-child(odd) {
    border-left: 5px solid ${(props) => props.theme.colors.vodafoneRed};
  }

  .card__details {
    padding: 0 10px;

    h3 {
      margin: 20px 0;
    }

    h2 {
      margin: 20px 0;
    }

    hr {
      border: 0;
      background-color: ${(props) => props.theme.colors.silverGrey};
      height: 2px;
      margin: 0;
    }

    .card__details__footer {
      img {
        margin-right: 5px;
      }
    }
  }
`;
