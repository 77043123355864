import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ChartDonut, ChartLegend } from '@patternfly/react-charts';

import HeroBalloons from '../../assets/heros/hero-balloons.png';
import HeroBanner from '../../components/HeroBanner';
import environment from '../../environment';
import { IGenericEntry } from '../../interfaces/IGenericSection';
import ControlledSelect from '../../components/ControlledSelect';
import StyledSystemReporting from './styles';
import GenericCard from '../../components/GenericCard';
import { dateHourFormatter } from '../../utils';
import InnerAccordion from '../../components/InnerAccordion';
import ExpandRight from '../../components/ExpandRight';

interface ICatalogueAuditFile {
  filename: string;
  headerFilename: string;
  importDate: string;
  transactionCount: number;
  insertedCount: number;
  updatedCount: number;
  deletedCount: number;
  status: string;
  fileType: string;
}

interface ICatalogueAuditFileType {
  fileType: string;
  files: ICatalogueAuditFile[];
}

const SystemReporting: React.FC = () => {
  const clearOrderSummary = {
    totalOrders: 0,
    totalOrdersSummary: {
      ACCEPTED: 0,
      REJECTED: 0
    },
    originSummary: {},
    segmentSummary: {}
  };
  const [timeframes] = useState<IGenericEntry[]>([
    {
      code: 'day',
      value: 'Today'
    },
    {
      code: 'week',
      value: 'This week'
    },
    {
      code: 'year',
      value: 'This year'
    }
  ]);
  const [timeframe, setTimeframe] = useState<IGenericEntry>(timeframes[0]);
  const [orderSummary, setOrderSummary] = useState(clearOrderSummary);
  const [errorSummary, setErrorSummary] = useState([]);
  const [totalErrors, setTotalErrors] = useState(0);
  const [catalogueUpdates, setCatalogueUpdates] = useState({
    latestUpdates: [],
    fileUpdates: []
  });

  const getOrderSummary = (timeframeParam: IGenericEntry) => {
    axios
      .get(
        `${environment.apiPath}getSummaryPageItems?timeframe=${timeframeParam.code}`,
        {
          ...environment.params
        }
      )
      .then(
        (res) =>
          setOrderSummary({
            totalOrders: res.data.totalOrders,
            totalOrdersSummary: {
              ...orderSummary.totalOrdersSummary,
              ...res.data.totalOrdersSummary
            },
            originSummary: res.data.originSummary,
            segmentSummary: res.data.segmentSummary
          }),
        (err) => toast.error(err.message)
      );
  };

  const getErrorSummary = (timeframeParam: IGenericEntry) => {
    axios
      .get(
        `${environment.apiPath}getSummaryPageItems?timeframe=${timeframeParam.code}&querytype=errors`,
        {
          ...environment.params
        }
      )
      .then(
        (res) => {
          setErrorSummary(res.data.errorSummary);
          setTotalErrors(
            res.data.errorSummary.reduce(
              (acc: number, val: any) => acc + val.count,
              0
            )
          );
        },
        (err) => toast.error(err.message)
      );
  };

  const getCatalogueAudit = () => {
    axios
      .get(`${environment.apiPath}getCatalogueAudit`, {
        ...environment.params
      })
      .then(
        (res) => setCatalogueUpdates(res.data),
        (err) => toast.error(err.message)
      );
  };

  useEffect(() => {
    getOrderSummary(timeframe);
    getErrorSummary(timeframe);
    getCatalogueAudit();
  }, []);

  const handleTimeframeChange = (event: any) => {
    setTimeframe(event.target.value);
    setOrderSummary(clearOrderSummary);
    setErrorSummary([]);
    getOrderSummary(event.target.value);
    getErrorSummary(event.target.value);
  };

  return (
    <>
      <HeroBanner title='System Reporting' background={HeroBalloons} />
      <StyledSystemReporting>
        <h2>Orders overview</h2>
        <ControlledSelect
          id='timeframe'
          value={timeframe}
          handleChange={handleTimeframeChange}
          label='Timeframe'
          options={timeframes}
          classes='label--w-20'
        />
        <div className='row'>
          <div className='col'>
            <h3>Total orders received</h3>
            <div className='chart'>
              <ChartDonut
                ariaDesc='Accepted/Rejected orders'
                ariaTitle='Accepted/Rejected orders'
                constrainToVisibleArea
                data={Object.entries(orderSummary.totalOrdersSummary).map(
                  // eslint-disable-next-line
                  (key, _) => ({ x: key[0], y: key[1] })
                )}
                height={325}
                labels={({ datum }) =>
                  `${datum.x}: ${Math.round(
                    (datum.y / orderSummary.totalOrders) * 100
                  )}%`
                }
                legendData={Object.entries(orderSummary.totalOrdersSummary).map(
                  // eslint-disable-next-line
                  (key, _) => ({ name: `${key[0]}: ${key[1]}` })
                )}
                legendPosition='bottom'
                padding={{
                  bottom: 65,
                  left: 20,
                  right: 20,
                  top: 20
                }}
                title={`${orderSummary.totalOrders}`}
                subTitle='Accepted/Rejected'
                width={400}
                innerRadius={90}
              />
            </div>
          </div>
          <div className='col'>
            <h3>Error summary</h3>
            <div className='chart'>
              <ChartDonut
                ariaDesc='Error summary'
                ariaTitle='Error summary'
                constrainToVisibleArea
                data={errorSummary.map((err: any) => ({
                  x: `${err.errorCode} - ${err.errorMessage}`,
                  y: err.count
                }))}
                height={325}
                labels={({ datum }) =>
                  `${datum.x}: ${Math.round((datum.y / totalErrors) * 100)}%`
                }
                legendPosition='bottom'
                padding={{
                  bottom: 65,
                  left: 20,
                  right: 20,
                  top: 20
                }}
                title={`${totalErrors}`}
                subTitle='Errors'
                width={400}
                innerRadius={90}
                legendComponent={
                  <ChartLegend
                    data={errorSummary.slice(0, 8).map((err: any) => ({
                      name: `${err.errorCode}: ${err.count}`
                    }))}
                    itemsPerRow={4}
                    height={400}
                  />
                }
              />
            </div>
          </div>
        </div>
        <div className='col'>
          <h3>Accepted orders</h3>
          <div className='row'>
            <div className='chart'>
              <ChartDonut
                ariaDesc='Accepted orders grouped by origin'
                ariaTitle='Accepted orders grouped by origin'
                constrainToVisibleArea
                data={Object.entries(orderSummary.originSummary).map(
                  // eslint-disable-next-line
                  (key, _) => ({
                    x: key[0],
                    y: key[1]
                  })
                )}
                height={325}
                labels={({ datum }) =>
                  `${datum.x}: ${Math.round(
                    (datum.y / orderSummary.totalOrdersSummary.ACCEPTED) * 100
                  )}%`
                }
                legendPosition='bottom'
                padding={{
                  bottom: 65,
                  left: 20,
                  right: 20,
                  top: 20
                }}
                title={`${orderSummary.totalOrdersSummary.ACCEPTED}`}
                subTitle='Origin'
                width={400}
                innerRadius={90}
                legendComponent={
                  <ChartLegend
                    data={Object.entries(orderSummary.originSummary)
                      .slice(0, 8)
                      // eslint-disable-next-line
                      .map((key, _) => ({ name: `${key[0]}: ${key[1]}` }))}
                    itemsPerRow={4}
                    height={400}
                  />
                }
              />
            </div>
            <div className='chart'>
              <ChartDonut
                ariaDesc='Accepted orders grouped by segment'
                ariaTitle='Accepted orders grouped by segment'
                constrainToVisibleArea
                data={Object.entries(orderSummary.segmentSummary).map(
                  // eslint-disable-next-line
                  (key, _) => ({
                    x: key[0],
                    y: key[1]
                  })
                )}
                height={325}
                labels={({ datum }) =>
                  `${datum.x}: ${Math.round(
                    (datum.y / orderSummary.totalOrdersSummary.ACCEPTED) * 100
                  )}%`
                }
                legendPosition='bottom'
                padding={{
                  bottom: 65,
                  left: 20,
                  right: 20,
                  top: 20
                }}
                title={`${orderSummary.totalOrdersSummary.ACCEPTED}`}
                subTitle='Segment'
                width={400}
                innerRadius={90}
                legendComponent={
                  <ChartLegend
                    data={Object.entries(orderSummary.segmentSummary)
                      .slice(0, 8)
                      // eslint-disable-next-line
                      .map((key, _) => ({ name: `${key[0]}: ${key[1]}` }))}
                    itemsPerRow={4}
                    height={400}
                  />
                }
              />
            </div>
          </div>
        </div>
        <h2>Product catalogue updates</h2>
        <div className='row catalogue-reporting'>
          {catalogueUpdates.latestUpdates.map((file: ICatalogueAuditFile) => (
            <GenericCard
              key={file.filename}
              title={file.fileType}
              subtitle={file.transactionCount.toString()}
              footer={dateHourFormatter(file.importDate)}
            />
          ))}
        </div>
        {catalogueUpdates.fileUpdates.map((audit: ICatalogueAuditFileType) => (
          <InnerAccordion key={audit.fileType} title={audit.fileType}>
            {audit.files.map((file: ICatalogueAuditFile) => (
              <ExpandRight
                key={file.filename}
                title={file.headerFilename}
                date={dateHourFormatter(file.importDate)}
                subtitle={[
                  {
                    code: 'Total transactions',
                    value: file.transactionCount.toString()
                  },
                  {
                    code: 'Inserted',
                    value: `${file.insertedCount} items`
                  },
                  {
                    code: 'Updated',
                    value: `${file.updatedCount} items`
                  },
                  {
                    code: 'Deleted',
                    value: `${file.deletedCount} items`
                  }
                ]}
                hideExpand
              />
            ))}
          </InnerAccordion>
        ))}
      </StyledSystemReporting>
    </>
  );
};

export default SystemReporting;
