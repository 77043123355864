import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IconButton } from '@material-ui/core';
import fileDownload from 'js-file-download';

import environment from '../../../../environment';
import DownloadIcon from '../../../../assets/icons/download-report.svg';

// eslint-disable-next-line
export default ({ data }: any): JSX.Element => {
  const buttonClicked = () => {
    axios
      .get(
        `${environment.apiPath}downloadReport?report=scheduled-reports&name=${data.filename}`,
        {
          responseType: 'blob',
          headers: {
            Accept:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ...environment.params.headers
          }
        }
      )
      .then(
        (res) => fileDownload(res.data, data.filename),
        (err) => toast.error(err.message)
      );
  };

  return (
    <IconButton
      tabIndex={0}
      aria-label='Download report'
      onClick={buttonClicked}
    >
      <img src={DownloadIcon} alt='Download' />
    </IconButton>
  );
};
