import React from 'react';

import GenericTitle from './components/GenericTitle/GenericTitle';
import GenericEntry from './components/GenericEntry/GenericEntry';
import {
  IGenericSection,
  IGenericEntry
} from '../../interfaces/IGenericSection';
import HiddenEntry from './components/HiddenEntry/HiddenEntry';

const GenericSection: React.SFC<IGenericSection> = ({ title, entries }) => (
  <>
    <GenericTitle img={title.img} altText={title.altText} title={title.title} />
    {entries.map((entry: IGenericEntry) =>
      entry.hideCustomerInfo ? (
        <HiddenEntry code={entry.code} />
      ) : (
        <GenericEntry key={entry.code} code={entry.code} value={entry.value} />
      )
    )}
  </>
);

export default GenericSection;
