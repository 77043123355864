import React, { useState } from 'react';
import { Button } from '@material-ui/core';

import StyledExpandRight from './styles';
import ExpandRightIcon from '../../assets/icons/expand-right.svg';
import CalendarIcon from '../../assets/icons/calendar-dark.svg';
import GenericEntry from '../GenericSection/components/GenericEntry';
import { IGenericEntry } from '../../interfaces/IGenericSection';

interface IExpandRightProps {
  title: string;
  subtitle?: IGenericEntry[];
  subinventoryEntries?: IGenericEntry[];
  titleTag?: string;
  description?: IGenericEntry[];
  redirectLink?: CallableFunction;
  hideExpand?: boolean;
  date?: string;
}

const ExpandRight: React.FC<IExpandRightProps> = ({
  title,
  subtitle,
  subinventoryEntries,
  titleTag,
  description,
  redirectLink,
  hideExpand,
  date,
  children
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledExpandRight expanded={expanded} hideExpand={hideExpand}>
      <div className='expand-right'>
        <div className='expand-right__title'>
          <div className='expand-right__title__row'>
            <h5>
              {titleTag && <span>{titleTag}</span>}
              {redirectLink ? (
                <button
                  type='button'
                  aria-label='Search item'
                  onClick={() => redirectLink()}
                >
                  {title}
                </button>
              ) : (
                title
              )}
            </h5>
            {date && (
              <div className='expand-right__title__row__date'>
                <img src={CalendarIcon} alt='Calendar' />
                <p>{date}</p>
              </div>
            )}
          </div>
          {description && description.length > 0 && (
            <div className='row expand-right__title__description'>
              {description.map(
                (entry: IGenericEntry) =>
                  entry.value && (
                    <GenericEntry
                      key={entry.code}
                      code={entry.code}
                      value={entry.value}
                    />
                  )
              )}
            </div>
          )}
          {subtitle && subtitle.length > 0 && (
            <div className='row expand-right__title__subtitle'>
              {subtitle.map(
                (entry: IGenericEntry) =>
                  entry.value && (
                    <GenericEntry
                      key={entry.code}
                      code={entry.code}
                      value={entry.value}
                      action={entry.action}
                      icon={entry.icon}
                    />
                  )
              )}
            </div>
          )}
        </div>
        {!hideExpand && (
          <Button className='expand-right__button' onClick={toggleExpand}>
            <img src={ExpandRightIcon} alt='Expand right' />
          </Button>
        )}
      </div>
      {expanded && (
        <div className='expand-right__content'>
          <div className='expand-right__content__details'>
            {subinventoryEntries &&
              subinventoryEntries.map((entry: IGenericEntry) =>
                entry.value || Number(entry.value) === 0 ? (
                  <GenericEntry
                    key={entry.code}
                    code={entry.code}
                    value={entry.value}
                  />
                ) : (
                  ''
                )
              )}
          </div>
          {children}
        </div>
      )}
    </StyledExpandRight>
  );
};

ExpandRight.defaultProps = {
  subtitle: [],
  titleTag: '',
  description: [],
  redirectLink: undefined,
  subinventoryEntries: [],
  hideExpand: false,
  date: ''
};

export default ExpandRight;
