import { Container } from '@material-ui/core';
import React from 'react';

import StyledHeroBanner from './styles';

const HeroBanner: React.SFC<{
  title: string;
  background: string;
  classes?: string;
}> = ({ title, background, classes }) => (
  <StyledHeroBanner
    background={background}
    className={classes}
    data-testid='hero-testid'
  >
    <Container>
      <h1>{title}</h1>
    </Container>
  </StyledHeroBanner>
);

HeroBanner.defaultProps = {
  classes: ''
};

export default HeroBanner;
