import React, { useState, SyntheticEvent } from 'react';
import {
  DialogTitle,
  DialogContent,
  Container,
  IconButton,
  MenuItem,
  Select
} from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';

import CustomButton from '../../../../components/CustomButton';
import cavendishTheme from '../../../../assets/theme';
import CloseIcon from '../../../../assets/icons/close.svg';
import CalendarIcon from '../../../../assets/icons/calendar.svg';
import StyledDialog from './styles';
import environment from '../../../../environment';
import LoadingScreen from '../../../../components/LoadigScreen';
import StyledFormControl from '../../../../components/ControlledSelect/styles';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import Label from '../../../../components/Label';

const reportFrequencies: string[] = ['hourly', 'daily', 'weekly', 'monthly'];

interface IScheduleReportModalProps {
  rowData: any[];
  setRowData: CallableFunction;
}

const ScheduleReportModal: React.FC<IScheduleReportModalProps> = ({
  rowData,
  setRowData
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState<any>('');
  const [frequency, setFrequency] = useState(reportFrequencies[0]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [reportQueries, setReportQueries] = useState([]);

  const handleResponse = () => {
    setQuery('');
    setFrequency(reportFrequencies[0]);
    setStartDate(null);
    setEndDate(null);
    setOpen(false);
    setLoading(false);
    setRowData([
      ...rowData,
      {
        reportName: query.queryName,
        frequency,
        startDate,
        endDate,
        enabled: true
      }
    ]);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);

    axios
      .post(
        `${environment.apiPath}scheduledReport`,
        {
          queryId: query.queryId,
          frequency,
          startDate: startDate ? moment.utc(startDate).format() : '',
          endDate: endDate ? moment.utc(endDate).format() : ''
        },
        { ...environment.params }
      )
      .then(
        () => {
          toast.success('Report successfully scheduled');
          handleResponse();
        },
        (err) => {
          toast.error(err.message);
          handleResponse();
        }
      );
  };

  const openScheduleReportModal = () => {
    axios
      .get(`${environment.apiPath}reportQuery`, {
        ...environment.params
      })
      .then(
        (res) => {
          setReportQueries(res.data);
          setOpen(true);
        },
        (err) => toast.error(err.message)
      );
  };

  return (
    <>
      <CustomButton
        type='button'
        classes='btn--w-200-px btn--blue'
        title='Schedule'
        handleClick={() => openScheduleReportModal()}
        endIcon={<img src={CalendarIcon} alt='Schedule report' />}
      />

      <StyledDialog
        fullScreen
        open={open}
        aria-labelledby='dialog-title'
        PaperProps={{
          style: {
            backgroundColor: cavendishTheme.colors.black
          }
        }}
      >
        <Container fixed className='close-btn'>
          <IconButton
            tabIndex={0}
            aria-label='Close'
            onClick={() => setOpen(false)}
            className='close'
          >
            <img src={CloseIcon} alt='Close' />
          </IconButton>
        </Container>

        <DialogTitle id='dialog-title'>Schedule Orders Report</DialogTitle>
        <hr />
        <DialogContent className='dialog-content'>
          <form
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
            className='dialog-content__form'
          >
            <Label
              id='search-query'
              control={
                <StyledFormControl variant={'outlined' as any}>
                  <Select
                    data-testid='select-query-testid'
                    displayEmpty
                    value={query}
                    onChange={(event: any) => setQuery(event.target.value)}
                  >
                    <MenuItem key='placeholder' value='' disabled>
                      Select report query
                    </MenuItem>
                    {reportQueries.map((q: any) => (
                      <MenuItem key={q.queryId} value={q}>
                        {q.queryName}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              }
              label='Report name'
              classes='label--w-100'
            />
            <CustomDatePicker
              id='start-date'
              value={startDate}
              handleChange={(event: any) => setStartDate(event)}
              label='Start date'
            />
            <CustomDatePicker
              id='end-date'
              value={endDate}
              handleChange={(event: any) => setEndDate(event)}
              label='End date'
            />
            <Label
              id='frequency'
              control={
                <StyledFormControl variant={'outlined' as any}>
                  <Select
                    data-testid='select-frequency-testid'
                    displayEmpty
                    value={frequency}
                    onChange={(event: any) => setFrequency(event.target.value)}
                  >
                    <MenuItem key='placeholder' value='' disabled>
                      Select frequency
                    </MenuItem>
                    {reportFrequencies.map((fqy: string) => (
                      <MenuItem key={fqy} value={fqy}>
                        {fqy.toLocaleUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              }
              label='Frequency'
              classes='label--w-100'
            />
            <CustomButton
              title='Schedule'
              type='submit'
              classes='btn--w-100'
              disabled={!query}
            />
          </form>
        </DialogContent>
        {loading && <LoadingScreen />}
      </StyledDialog>
    </>
  );
};

export default ScheduleReportModal;
