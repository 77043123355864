import React, { useState, useEffect, SyntheticEvent } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import HeroBalloons from '../../assets/heros/hero-balloons.png';
import HeroBanner from '../../components/HeroBanner';
import SearchMenu from './components/SearchMenu/SearchMenu';
import environment from '../../environment';
import { IGenericEntry } from '../../interfaces/IGenericSection';
import StyledReport from './styles';
import ControlledInput from '../../components/ControlledInput';
import CustomButton from '../../components/CustomButton';
import DownloadIcon from '../../assets/icons/download.svg';
import ArrowRight from '../../assets/icons/arrow-right.svg';

const Reporting: React.FC = () => {
  const [rowData, setRowData] = useState([]);
  const [productCode, setProductCode] = useState<string>('');
  const [productCodes, setProductCodes] = useState<string[]>([]);
  const [productDescription, setProductDescription] = useState<string>('');
  const [stockPot, setStockPot] = useState<string>('');
  const [statusOperators] = useState<IGenericEntry[]>([
    {
      code: 'equals',
      value: 'Equals'
    },
    {
      code: 'lesserEquals',
      value: 'Lesser equals'
    },
    {
      code: 'greaterEquals',
      value: 'Greater equals'
    }
  ]);
  const [statusOperator, setStatusOperator] = useState<IGenericEntry>(
    statusOperators[0]
  );
  const [status, setStatus] = useState<string>('');
  const [createDateOptions] = useState<IGenericEntry[]>([
    {
      code: 'customDate',
      value: 'Custom date'
    }
  ]);
  const [createDateOption, setCreateDateOption] = useState<IGenericEntry>(
    createDateOptions[0]
  );
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [subinventories, setSubinventories] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [gridApi, setGridApi] = useState<any>();
  const [quickFilter, setQuickFilter] = useState<string>('');
  const history = useHistory();

  const getRowData = () => {
    let rows: any = [];
    let requestsWaiting = 1;

    const queryData = (executionId = '', nextOffset = '', pageSize = '') => {
      axios
        .get(
          `${
            environment.apiPath
          }getReservationsReportItems?queryExecutionId=${executionId}&nextOffset=${nextOffset}&pageSize=${pageSize}&productCodes=${productCodes.join(
            ','
          )}&descriptions=${productDescription}&stockPot=${stockPot}&status=${status}&statusOperator=${
            statusOperator.code
          }&createDateStart=${
            startDate ? moment(startDate).format('YYYY-MM-DD') : ''
          }&createDateEnd=${
            endDate ? moment(endDate).format('YYYY-MM-DD') : ''
          }`,
          {
            ...environment.params
          }
        )
        .then(
          (res) => {
            rows = rows.concat(
              res.data.rows.map((row: any[]) =>
                _.zipObject(res.data.columns, row)
              )
            );

            if (res.data.nextOffsets.length > 0) {
              requestsWaiting = res.data.nextOffsets.length;
              res.data.nextOffsets.map((token: any) =>
                queryData(
                  res.data.queryExecutionId,
                  token.offset,
                  token.pageSize
                )
              );
            } else {
              requestsWaiting -= 1;
            }

            if (requestsWaiting === 0) {
              rows.forEach((row: any) => {
                const orderDate = moment(row.orderDate, 'DD/MM/YYYY').format(
                  'MM/DD/YYYY'
                );
                const date = new Date(`${orderDate} ${row.orderTime} UTC`);
                row.orderDate = moment(date).format('DD/MM/YYYY');
                row.orderTime = moment(date).format('HH:mm:ss');
              });
              setRowData(rows);
            }

            if (res.data.rows.length === 0) {
              gridApi.showNoRowsOverlay();
            }
          },
          (err) => {
            setRowData([]);
            gridApi.showNoRowsOverlay();
            toast.error(err.message);
          }
        );
    };

    queryData();
  };

  const getSubinventoryList = () => {
    axios
      .get(`${environment.apiPath}getSubinventoriesList`, {
        ...environment.params
      })
      .then(
        (res) => setSubinventories(res.data),
        (err) => toast.error(err.message)
      );
  };

  const getStatusList = () => {
    axios
      .get(`${environment.apiPath}getItemStatusList`, {
        ...environment.params
      })
      .then(
        (res) => setStatuses(res.data),
        (err) => toast.error(err.message)
      );
  };

  useEffect(() => {
    getSubinventoryList();
    getStatusList();
  }, []);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    gridApi?.showLoadingOverlay();
    getRowData();
  };

  const handleDataExport = () => {
    gridApi?.exportDataAsCsv();
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  return (
    <>
      <HeroBanner title='Order Reporting' background={HeroBalloons} />
      <SearchMenu
        productDescription={productDescription}
        setProductDescription={setProductDescription}
        statusOperator={statusOperator}
        setStatusOperator={setStatusOperator}
        statusOperators={statusOperators}
        handleSubmit={handleSubmit}
        createDateOption={createDateOption}
        setCreateDateOption={setCreateDateOption}
        createDateOptions={createDateOptions}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        stockPot={stockPot}
        setStockPot={setStockPot}
        subinventories={subinventories}
        status={status}
        setStatus={setStatus}
        statuses={statuses}
        productCode={productCode}
        setProductCode={setProductCode}
        productCodes={productCodes}
        setProductCodes={setProductCodes}
      />
      <StyledReport fixed>
        <div className='report__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: any) => {
              setQuickFilter(event.target.value);
              gridApi?.setQuickFilter(event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <div>
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--black'
              title='Export'
              endIcon={<img src={DownloadIcon} alt='Download report' />}
              handleClick={handleDataExport}
            />
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--light-grey btn--ml-10'
              title='Schedule reports'
              endIcon={
                <img
                  src={ArrowRight}
                  alt='Navigate to scheduled reports page'
                />
              }
              handleClick={() => history.push('/reporting/scheduled-reports')}
            />
          </div>
        </div>
        <div className='report__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 200,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            suppressRowClickSelection
            pagination
            rowData={rowData}
            onGridReady={onGridReady}
            rowGroupPanelShow='always'
            suppressDragLeaveHidesColumns
          >
            <AgGridColumn headerName='Item' groupId='productGroup'>
              <AgGridColumn
                headerName='SKU'
                field='sku'
                minWidth={100}
                enableRowGroup
              />
              <AgGridColumn
                field='description'
                columnGroupShow='open'
                minWidth={300}
              />
              <AgGridColumn field='status' minWidth={150} enableRowGroup />
              <AgGridColumn
                field='subinventory'
                minWidth={150}
                enableRowGroup
              />
              <AgGridColumn
                headerName='Line'
                field='orderLine'
                columnGroupShow='open'
                minWidth={100}
              />
              <AgGridColumn
                headerName='Acq/Ret'
                field='acquisition'
                columnGroupShow='open'
                minWidth={100}
                enableRowGroup
              />
              <AgGridColumn
                field='priority'
                sortable
                columnGroupShow='open'
                filter='agNumberColumnFilter'
                minWidth={100}
                enableRowGroup
              />
              <AgGridColumn
                field='quantity'
                columnGroupShow='open'
                filter='agNumberColumnFilter'
                type='numericColumn'
                minWidth={100}
              />
              <AgGridColumn
                headerName='Allocated Qty'
                field='allocQuantity'
                columnGroupShow='open'
                filter='agNumberColumnFilter'
                type='numericColumn'
                minWidth={100}
              />
              <AgGridColumn
                headerName='Backorder Qty'
                field='BOQuantity'
                columnGroupShow='open'
                filter='agNumberColumnFilter'
                type='numericColumn'
                minWidth={100}
              />
              <AgGridColumn
                headerName='Preorder Qty'
                field='POQuantity'
                columnGroupShow='open'
                filter='agNumberColumnFilter'
                type='numericColumn'
                minWidth={100}
              />
              <AgGridColumn
                headerName='Picked Qty'
                field='pickedQuantity'
                columnGroupShow='open'
                filter='agNumberColumnFilter'
                type='numericColumn'
                minWidth={100}
              />
              <AgGridColumn
                headerName='Despatched Qty'
                field='despatchedQuantity'
                columnGroupShow='open'
                filter='agNumberColumnFilter'
                type='numericColumn'
                minWidth={100}
              />
            </AgGridColumn>

            <AgGridColumn headerName='Order' groupId='orderGroup'>
              <AgGridColumn
                headerName='Order ID'
                field='clientOrderNo'
                minWidth={200}
                enableRowGroup
              />
              <AgGridColumn
                headerName='Order date'
                field='orderDate'
                minWidth={200}
                sortable
                filter='agDateColumnFilter'
                filterParams={{
                  comparator: (
                    filterLocalDateAtMidnight: Date,
                    cellValue: string
                  ) => {
                    const dateAsString = cellValue;
                    const dateParts = dateAsString.split('/');
                    const cellDate = new Date(
                      Number(dateParts[2]),
                      Number(dateParts[1]) - 1,
                      Number(dateParts[0])
                    );

                    if (
                      filterLocalDateAtMidnight.getTime() === cellDate.getTime()
                    ) {
                      return 0;
                    }

                    if (cellDate < filterLocalDateAtMidnight) {
                      return -1;
                    }

                    return 1;
                  }
                }}
              />
              <AgGridColumn
                headerName='Order time'
                field='orderTime'
                sortable
                minWidth={100}
              />
              <AgGridColumn
                field='origin'
                columnGroupShow='open'
                minWidth={100}
              />
              <AgGridColumn
                field='segment'
                columnGroupShow='open'
                minWidth={100}
              />
              <AgGridColumn
                headerName='Billing account number'
                field='ban'
                columnGroupShow='open'
                minWidth={100}
              />
            </AgGridColumn>

            <AgGridColumn headerName='Delivery group' groupId='dgGroup'>
              <AgGridColumn field='carrier' minWidth={100} />
              <AgGridColumn field='service' minWidth={100} />
            </AgGridColumn>
            <AgGridColumn headerName='Others' groupId='dgGroup'>
              <AgGridColumn
                headerName='Source'
                field='source'
                minWidth={100}
                enableRowGroup
              />
            </AgGridColumn>
          </AgGridReact>
        </div>
      </StyledReport>
    </>
  );
};

export default Reporting;
