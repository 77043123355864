import React, { useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { toast } from 'react-toastify';

import HeroBalloons from '../../assets/heros/hero-balloons.png';
import HeroBanner from '../../components/HeroBanner';
import environment from '../../environment';
import StyledConfiguration from './styles';
import ControlledInput from '../../components/ControlledInput';
import CustomButton from '../../components/CustomButton';
import PageTitle from '../../components/PageTitle';
import DownloadIcon from '../../assets/icons/download.svg';

const StateChangeAddons: React.FC = () => {
  const [rowData, setRowData] = useState([]);

  const [gridApi, setGridApi] = useState<any>();
  const [quickFilter, setQuickFilter] = useState<string>('');

  const getRowData = (gridApiParam: any) => {
    axios
      .get(`${environment.apiPath}getConfigItems?page=statechangeaddon`, {
        ...environment.params
      })
      .then(
        (res) => {
          setRowData(res.data);
          if (res.data.length === 0) {
            gridApiParam?.showNoRowsOverlay();
          }
        },
        (err) => {
          setRowData([]);
          toast.error(err.message);
          gridApiParam?.showNoRowsOverlay();
        }
      );
  };

  const handleDataExport = () => {
    gridApi?.exportDataAsCsv();
  };

  const onGridReady = (params: any) => {
    params.api?.showLoadingOverlay();
    setGridApi(params.api);
    getRowData(params.api);
  };

  return (
    <>
      <HeroBanner title='Configuration' background={HeroBalloons} />
      <PageTitle pageTitle='State Change Add-ons' />
      <StyledConfiguration fixed>
        <div className='configuration__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: any) => {
              setQuickFilter(event.target.value);
              gridApi?.setQuickFilter(event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <div>
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--black'
              title='Export'
              endIcon={<img src={DownloadIcon} alt='Download configuration' />}
              handleClick={handleDataExport}
            />
          </div>
        </div>
        <div className='configuration__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 100,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            suppressRowClickSelection
            pagination
            rowData={rowData}
            onGridReady={onGridReady}
            rowGroupPanelShow='always'
            suppressDragLeaveHidesColumns
          >
            <AgGridColumn field='targetProduct' enableRowGroup />
            <AgGridColumn field='targetProductName' enableRowGroup />
            <AgGridColumn field='origin' enableRowGroup />
            <AgGridColumn field='segment' enableRowGroup />
            <AgGridColumn field='oldState' enableRowGroup />
            <AgGridColumn field='newState' enableRowGroup />
            <AgGridColumn
              headerName='Add-on Product'
              field='addOnProduct'
              enableRowGroup
            />
            <AgGridColumn
              headerName='Add-on Product Name'
              field='addOnProductName'
              enableRowGroup
            />
            <AgGridColumn
              headerName='Add-on backorder'
              field='addOnBackorder'
              cellRenderer={(params) => (params.value ? 'Y' : 'N')}
            />
            <AgGridColumn
              headerName='Status'
              field='active'
              cellRenderer={(params) => (params.value ? 'Active' : 'Inactive')}
              enableRowGroup
            />
          </AgGridReact>
        </div>
      </StyledConfiguration>
    </>
  );
};

export default StateChangeAddons;
