import React, { useEffect, useState } from 'react';
import { AccordionSummary, AccordionDetails } from '@material-ui/core';

import ExpandIcon from '../../assets/icons/expand.svg';
import Status from '../Status';
import StyledAccordion from './styles';
import cavendishTheme from '../../assets/theme';
import GenericEntry from '../GenericSection/components/GenericEntry';
import HiddenEntry from '../GenericSection/components/HiddenEntry';
import { IGenericEntry } from '../../interfaces/IGenericSection';
import { CancelledState, SuccessStates } from '../../utils';

interface ICustomAccordionProps {
  primaryTitle: string;
  secondaryTitle: string;
  status: string;
  isDeleted?: boolean;
  summaryEntries: IGenericEntry[];
}

const CustomAccordion: React.SFC<ICustomAccordionProps> = ({
  primaryTitle,
  secondaryTitle,
  status,
  isDeleted,
  summaryEntries,
  children
}) => {
  const [color, setColor] = useState(cavendishTheme.colors.purple);

  useEffect(() => {
    if (isDeleted) {
      setColor(cavendishTheme.colors.vodafoneRed);
    } else if (SuccessStates.includes(status.toLocaleLowerCase())) {
      setColor(cavendishTheme.colors.blue);
    } else if (status.toLocaleLowerCase() === CancelledState) {
      setColor(cavendishTheme.colors.darkGrey);
    }
  }, [status, isDeleted]);

  return (
    <StyledAccordion color={color}>
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt='Expand' />}
        className='custom-accordion__summary accordion__summary--outlined'
      >
        <Status color={color} />
        <div className='row'>
          <div className='custom-accordion__summary__title column'>
            <p>{secondaryTitle}</p>
            <h2>{primaryTitle}</h2>
            <GenericEntry
              code='Status'
              value={isDeleted ? 'Deleted' : status}
            />
          </div>
          <div className='custom-accordion__summary__entries column'>
            {summaryEntries.map((entry: IGenericEntry) =>
              entry.hideCustomerInfo ? (
                <HiddenEntry code={entry.code} />
              ) : (
                <GenericEntry
                  key={entry.code}
                  code={entry.code}
                  value={entry.value}
                />
              )
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className='custom-accordion__details accordion__details--display-column'>
        {children}
      </AccordionDetails>
    </StyledAccordion>
  );
};

CustomAccordion.defaultProps = {
  isDeleted: false
};

export default CustomAccordion;
