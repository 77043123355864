import { Container } from '@material-ui/core';
import styled from 'styled-components';

export default styled(Container)`
  .configuration__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .configuration__grid {
    height: 600px;
    width: 100%;
    margin: 30px 0;
    font-size: 14px;
    font-family: Vodafone Lt;
  }
`;
