import React from 'react';

import StyledButton from './styles';

interface ICustomButtonProps {
  title: string;
  type?: any;
  disabled?: boolean;
  startIcon?: any;
  endIcon?: any;
  classes?: string;
  handleClick?: any;
}

const CustomButton: React.SFC<ICustomButtonProps> = ({
  type = 'button',
  title,
  disabled = false,
  classes,
  handleClick,
  ...iconProps
}) => (
  <StyledButton
    className={classes}
    variant='contained'
    type={type}
    disabled={disabled}
    onClick={handleClick}
    {...iconProps}
  >
    {title}
  </StyledButton>
);

CustomButton.defaultProps = {
  type: 'button',
  disabled: false,
  startIcon: null,
  endIcon: null,
  classes: '',
  handleClick: null
};

export default CustomButton;
