import React, { useState, useEffect, SyntheticEvent } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';

import HeroBalloons from '../../assets/heros/hero-balloons.png';
import HeroBanner from '../../components/HeroBanner';
import SearchMenu from './components/SearchMenu/SearchMenu';
import environment from '../../environment';
import StyledReport from './styles';
import ControlledInput from '../../components/ControlledInput';
import CustomButton from '../../components/CustomButton';
import DownloadIcon from '../../assets/icons/download.svg';

const StockAlignmentReporting: React.FC = () => {
  const [rowData, setRowData] = useState([]);
  const [productCode, setProductCode] = useState<string>('');
  const [stockPot, setStockPot] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [snapshotDate, setSnapshotDate] = useState<Date | null>(null);
  const [subinventories, setSubinventories] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [gridApi, setGridApi] = useState<any>();
  const [quickFilter, setQuickFilter] = useState<string>('');

  const getRowData = () => {
    let rows: any = [];

    const queryData = () => {
      axios
        .get(
          `${
            environment.apiPath
          }getInventoryAlignmentReport?sku=${productCode}&subinventory=${stockPot}&status=${status}&date=${
            snapshotDate ? moment(snapshotDate).format('YYYY-MM-DD') : ''
          }`,
          {
            ...environment.params
          }
        )
        .then(
          (res) => {
            rows = rows.concat(
              res.data.rows.map((row: any[]) =>
                _.zipObject(res.data.columns, row)
              )
            );
            setRowData(rows);

            if (res.data.rows.length === 0) {
              gridApi.showNoRowsOverlay();
            }
          },
          (err) => {
            setRowData([]);
            gridApi.showNoRowsOverlay();
            toast.error(err.response.data.error);
          }
        );
    };

    queryData();
  };

  const getSubinventoryList = () => {
    axios
      .get(
        `${environment.apiPath}getSubinventoriesList?type=inventorySnapshot`,
        {
          ...environment.params
        }
      )
      .then(
        (res) => setSubinventories(res.data),
        (err) => toast.error(err.message)
      );
  };

  const getStatusList = () => {
    axios
      .get(`${environment.apiPath}getStockAlignmentStatusList`, {
        ...environment.params
      })
      .then(
        (res) => setStatuses(res.data),
        (err) => toast.error(err.message)
      );
  };

  useEffect(() => {
    getSubinventoryList();
    getStatusList();
  }, []);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    gridApi?.showLoadingOverlay();
    getRowData();
  };

  const handleDataExport = () => {
    gridApi?.exportDataAsCsv();
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  return (
    <>
      <HeroBanner title='Stock Alignment Reporting' background={HeroBalloons} />
      <SearchMenu
        handleSubmit={handleSubmit}
        snapshotDate={snapshotDate}
        setSnapshotDate={setSnapshotDate}
        stockPot={stockPot}
        setStockPot={setStockPot}
        subinventories={subinventories}
        status={status}
        setStatus={setStatus}
        statuses={statuses}
        productCode={productCode}
        setProductCode={setProductCode}
      />
      <StyledReport fixed>
        <div className='report__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: any) => {
              setQuickFilter(event.target.value);
              gridApi?.setQuickFilter(event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <div>
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--black'
              title='Export'
              endIcon={<img src={DownloadIcon} alt='Download report' />}
              handleClick={handleDataExport}
            />
          </div>
        </div>
        <div className='report__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 200,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            suppressRowClickSelection
            pagination
            rowData={rowData}
            onGridReady={onGridReady}
            rowGroupPanelShow='always'
            suppressDragLeaveHidesColumns
          >
            <AgGridColumn
              headerName='SKU'
              field='sku'
              maxWidth={80}
              enableRowGroup
            />
            <AgGridColumn field='description' minWidth={350} />
            <AgGridColumn
              field='sellable'
              maxWidth={90}
              cellRenderer={(params) => (params.value ? 'Y' : 'N')}
            />
            <AgGridColumn field='subinventory' minWidth={150} enableRowGroup />
            <AgGridColumn
              field='status'
              minWidth={100}
              enableRowGroup
              sortable
            />
            <AgGridColumn
              headerName='Cavendish SOH'
              field='cavendishStockOnHand'
              maxWidth={130}
              sortable
            />
            <AgGridColumn
              headerName='RMS SOH'
              field='rmsStockOnHand'
              maxWidth={100}
              sortable
            />
            <AgGridColumn
              headerName='Difference'
              field='difference'
              maxWidth={100}
              sortable
            />
            <AgGridColumn
              field='importDate'
              maxWidth={120}
              sortable
              filter='agDateColumnFilter'
            />
          </AgGridReact>
        </div>
      </StyledReport>
    </>
  );
};

export default StockAlignmentReporting;
