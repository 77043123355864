import styled from 'styled-components';

export default styled.header`
  .header__primary-menu {
    height: 30px;
    background-color: ${(props) => props.theme.colors.darkGrey};
  }
  .MuiContainer-maxWidthLg {
    max-width: 1500px;
  }

  .header__secondary-menu {
    display: flex;
    justify-content: space-between;

    .header__secondary-menu__navigation {
      display: flex;

      img {
        width: 50px;
        height: 50px;
        margin: 15px 15px 15px 0;
      }

      .header__secondary-menu__navigation__tab {
        margin: 10px 0 5px 0;
        color: ${(props) => props.theme.colors.black};
        opacity: 1;

        &.Mui-selected {
          color: ${(props) => props.theme.colors.vodafoneRed};
        }

        .MuiTab-wrapper {
          width: fit-content;
          text-transform: capitalize;
          font-family: Vodafone Lt;
          font-size: 24px;
        }
      }

      .MuiTabs-indicator {
        background-color: ${(props) => props.theme.colors.vodafoneRed};
        height: 3px;
      }
    }
  }
`;
