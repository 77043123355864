import React, { useEffect, useCallback, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  Tabs,
  Tab,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import { Auth } from 'aws-amplify';

import VodafoneLogo from '../../assets/icons/vodafone-logo.svg';
import LoginIcon from '../../assets/icons/user.svg';
import LogoutIcon from '../../assets/icons/logout.svg';
import StyledHeader from './styles';
import { IAuthState } from '../../interfaces/IAuthState';

interface IHeaderMenu {
  label: string;
  route: string;
}
interface IHeaderTab {
  label: string;
  route: string;
  menus?: IHeaderMenu[];
}

interface IHeaderProps extends RouteComponentProps {
  userState: IAuthState;
  setUserState: (userState: IAuthState) => void;
}

const headerTabs: IHeaderTab[] = [
  {
    label: 'Home',
    route: '/'
  }
];

const authHeaderTabs: IHeaderTab[] = [
  {
    label: 'Order Management',
    route: '/order-management'
  },
  {
    label: 'Inventory Management',
    route: '/inventory-management'
  }
];

const configHeaderTab: IHeaderTab = {
  label: 'Configuration',
  route: '/configuration',
  menus: [
    {
      label: 'Hierarchy Delivery Mapping',
      route: '/configuration/hierarchy-delivery'
    },
    {
      label: 'Origin Segment Mapping',
      route: '/configuration/origin-segment'
    },
    {
      label: 'Return Bag Overrides',
      route: '/configuration/return-bag-overrides'
    },
    {
      label: 'State Change Add-ons',
      route: '/configuration/state-change-addons'
    },
    {
      label: 'Product Present Add-ons',
      route: '/configuration/product-present-addons'
    },
    {
      label: 'Hierarchy Add-ons',
      route: '/configuration/hierarchy-addons'
    },
    {
      label: 'Low Value Delivery Override',
      route: '/configuration/low-value-delivery-override'
    }
  ]
};

const reportingHeaderTab: IHeaderTab = {
  label: 'Reporting',
  route: '/reporting',
  menus: [
    {
      label: 'System Reporting',
      route: '/reporting/system'
    },
    {
      label: 'Order Reporting',
      route: '/reporting/order'
    },
    {
      label: 'Returns Reporting',
      route: '/reporting/returns'
    },
    {
      label: 'Stock Alignment Reporting',
      route: '/reporting/stock-alignment'
    }
  ]
};

const fullHeaderList: IHeaderTab[] = [
  ...headerTabs,
  ...authHeaderTabs,
  reportingHeaderTab,
  configHeaderTab
];

const Header: React.FC<IHeaderProps> = ({
  history,
  userState,
  setUserState
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  let allHeaderTabs =
    userState.authState === 'signedIn'
      ? [...headerTabs, ...authHeaderTabs]
      : headerTabs;
  const [menuParentElem, setMenuParentElem] = useState(null);
  const [submenus, setSubmenus] = useState<IHeaderMenu[]>([]);

  if (userState.permissions?.viewOrderReports === true)
    allHeaderTabs = [...allHeaderTabs, reportingHeaderTab];

  if (userState.permissions?.viewConfigInfo === true)
    allHeaderTabs = [...allHeaderTabs, configHeaderTab];

  useEffect(() => {
    const currentTabIndex: number = allHeaderTabs.findIndex(
      (tab: IHeaderTab) =>
        tab.route === history.location.pathname ||
        history.location.pathname.startsWith(`${tab.route}/`)
    );
    setTabIndex(currentTabIndex);
  }, [history.location]);

  const handleConfigMenuClick = useCallback(
    (route: string, allTabs: IHeaderTab[]) => () => {
      setMenuParentElem(null);
      setTabIndex(
        allTabs.findIndex((tab: IHeaderTab) =>
          route.startsWith(`${tab.route}/`)
        )
      );
      history.push(route);
    },
    [history]
  );

  const handleTabChange = useCallback(
    (newTabIndex: number, route: string) => (event: any) => {
      const selTab = fullHeaderList.find(
        (tab: IHeaderTab) => tab.route === route
      );

      if (selTab && selTab.menus) {
        setSubmenus(selTab.menus);
        setMenuParentElem(event.target);
      } else {
        setTabIndex(newTabIndex);
        history.push(route);
      }
    },
    [history]
  );

  const handleSignOut = async () => {
    await Auth.signOut();
    setUserState({ authState: 'signedOut', user: undefined });
  };

  return (
    <StyledHeader>
      <div className='header__primary-menu' />
      <Container fixed className='header__secondary-menu'>
        <div className='header__secondary-menu__navigation'>
          <img src={VodafoneLogo} alt='Vodafone Logo' />
          <Tabs value={tabIndex}>
            {allHeaderTabs.map((tab, index) => (
              <Tab
                className='header__secondary-menu__navigation__tab'
                tabIndex={0}
                key={tab.label}
                label={tab.label}
                onClick={handleTabChange(index, tab.route)}
              />
            ))}
          </Tabs>
        </div>
        <Menu
          id='config-menu'
          keepMounted
          open={Boolean(menuParentElem)}
          onClose={() => setMenuParentElem(null)}
          anchorEl={menuParentElem}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {submenus.map((menu) => (
            <MenuItem
              key={menu.label}
              style={{ fontFamily: 'Vodafone Lt' }}
              onClick={handleConfigMenuClick(menu.route, allHeaderTabs)}
            >
              {menu.label}
            </MenuItem>
          ))}
        </Menu>
        {userState.authState === 'signedIn' ? (
          <Tooltip
            title={
              <>
                <p>User: {userState.user?.userData.attributes.name}</p>
                <p>Group: {userState.user?.userData.attributes.profile}</p>
              </>
            }
            arrow
          >
            <IconButton
              tabIndex={0}
              aria-label='Logout'
              onClick={handleSignOut}
            >
              <img src={LogoutIcon} alt='Logout' />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            tabIndex={0}
            aria-label='Login'
            onClick={() => Auth.federatedSignIn({ customProvider: 'IdP' })}
          >
            <img src={LoginIcon} alt='Login' />
          </IconButton>
        )}
      </Container>
    </StyledHeader>
  );
};

export default withRouter(Header);
