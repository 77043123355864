import styled from 'styled-components';

export default styled.p`
  color: ${(props) => props.theme.colors.black};
  font-family: Vodafone Lt;

  span {
    font-weight: bolder;
  }

  .tag {
    color: ${(props) => props.theme.colors.vodafoneRed};
  }
`;
