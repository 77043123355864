import styled from 'styled-components';

export default styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  span:hover {
    color: ${(props) => props.theme.colors.vodafoneRed};
  }
`;
