import React from 'react';

import CalendarIcon from '../../assets/icons/calendar-dark.svg';
import StyledGenericCard from './styles';

const GenericCard: React.SFC<{
  title: string;
  subtitle: string;
  footer: string;
}> = ({ title, subtitle, footer }) => (
  <StyledGenericCard>
    <div className='card__details'>
      <h3>{title}</h3>
      <h2>{subtitle}</h2>
      <hr />
      <div className='row card__details__footer'>
        <img src={CalendarIcon} alt='Calendar' />
        <p>{footer}</p>
      </div>
    </div>
  </StyledGenericCard>
);

export default GenericCard;
