import styled from 'styled-components';

export default styled.div`
  background-color: ${(props) => props.theme.colors.black};
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h2 {
    font-size: 24px;
    font-family: Vodafone Lt;
    color: ${(props) => props.theme.colors.white};
  }
`;
