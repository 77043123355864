import styled from 'styled-components';
import { Button } from '@material-ui/core';

export default styled(Button)`
  && {
    margin-top: 20px;
    border-radius: 0;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.vodafoneRed};
    text-transform: initial;
    font-family: Vodafone Rg;
    font-size: 20px;
    height: 50px;

    &:hover {
      background-color: ${(props) => props.theme.colors.darkRed};
    }

    &.Mui-disabled {
      color: ${(props) => props.theme.colors.darkGrey};
      background-color: ${(props) => props.theme.colors.silverGrey};
    }
  }

  &.btn--w-10 {
    width: 10%;
  }

  &.btn--w-20 {
    width: 20%;
  }

  &.btn--w-100 {
    width: 100%;
  }

  &.btn--w-200-px {
    width: 200px;
  }

  &.btn--small {
    font-size: 16px;
    height: 40px;
    margin: 10px 0 5px 5px;
  }

  &.btn--ml-10 {
    margin-left: 10px;
  }

  &.btn--grey {
    background-color: ${(props) => props.theme.colors.darkGrey};

    &:hover {
      background-color: ${(props) => props.theme.colors.black};
    }
  }

  &.btn--blue {
    background-color: ${(props) => props.theme.colors.blue};

    &:hover {
      background-color: ${(props) => props.theme.colors.lightBlue};
    }
  }

  &.btn--black {
    background-color: ${(props) => props.theme.colors.black};

    &:hover {
      background-color: ${(props) => props.theme.colors.darkGrey};
    }
  }

  &.btn--light-grey {
    background-color: ${(props) => props.theme.colors.shadowGrey};

    &:hover {
      background-color: ${(props) => props.theme.colors.silverGrey};
    }
  }
`;
