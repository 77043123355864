import React, { useState, SyntheticEvent, useEffect } from 'react';
import {
  DialogTitle,
  DialogContent,
  Container,
  IconButton
} from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';

import CustomButton from '../../../../components/CustomButton';
import cavendishTheme from '../../../../assets/theme';
import CloseIcon from '../../../../assets/icons/close.svg';
import StyledDialog from './styles';
import ControlledInput from '../../../../components/ControlledInput';
import environment from '../../../../environment';
import LoadingScreen from '../../../../components/LoadigScreen';
import { IReservationId } from '../../../../interfaces/IOrder';

interface IPriorityProps {
  reservationFullId?: IReservationId;
  priority: number;
  productCode: string;
  openState: boolean;
  handleClose?: CallableFunction;
  setUpdatedPriority?: CallableFunction;
}

const PriorityModal: React.FC<IPriorityProps> = ({
  reservationFullId,
  priority,
  productCode,
  openState,
  handleClose,
  setUpdatedPriority
}) => {
  const [open, setOpen] = useState(openState);
  const [loading, setLoading] = useState(false);
  const [newPriority, setNewPriority] = useState(priority);

  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  const localHandleSuccess = () => {
    setOpen(false);
    setLoading(false);
    if (handleClose) {
      handleClose();
    }
    if (setUpdatedPriority) {
      setUpdatedPriority(newPriority);
    }
  };

  const localHandleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(
        `${environment.apiPath}modifyReservation`,
        {
          reservationFullId,
          priority: parseInt(newPriority.toString(), 10)
        },
        { ...environment.params }
      )
      .then(
        () => localHandleSuccess(),
        (err) => {
          if (err.response) {
            toast.error(err.response.data.error);
          } else {
            toast.error(err.message);
          }
          setLoading(false);
          setOpen(false);
          if (handleClose) {
            handleClose();
          }
        }
      );
  };

  return (
    <>
      <StyledDialog
        fullScreen
        open={open}
        aria-labelledby='dialog-title'
        PaperProps={{
          style: {
            backgroundColor: cavendishTheme.colors.black
          }
        }}
      >
        <Container fixed className='close-btn'>
          <IconButton
            tabIndex={0}
            aria-label='Close'
            onClick={() => {
              setOpen(false);
              if (handleClose) {
                handleClose();
              }
            }}
            className='close'
          >
            <img src={CloseIcon} alt='Close' />
          </IconButton>
        </Container>

        <DialogTitle id='dialog-title'>{productCode}</DialogTitle>
        <h3>{reservationFullId?.reservationId}</h3>
        <hr />

        <DialogContent className='dialog-content'>
          <form
            noValidate
            autoComplete='off'
            onSubmit={localHandleSubmit}
            className='dialog-content__form'
          >
            <ControlledInput
              id='priority'
              label='Priority'
              type='number'
              value={newPriority}
              placeholder='Type new priority here'
              handleChange={(event: any) => setNewPriority(event.target.value)}
            />
            <CustomButton title='Update' type='submit' classes='btn--w-100' />
          </form>
        </DialogContent>
        {loading && <LoadingScreen />}
      </StyledDialog>
    </>
  );
};

PriorityModal.defaultProps = {
  reservationFullId: undefined,
  handleClose: undefined,
  setUpdatedPriority: undefined
};

export default PriorityModal;
