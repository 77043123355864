import React, { useState, ChangeEvent } from 'react';
import { Container } from '@material-ui/core';

import HeroBalloons from '../../assets/heros/hero-balloons.png';
import HeroBanner from '../../components/HeroBanner';
import SearchBar from '../../components/SearchBar';
import NoResultsScreen from '../../components/NoResultsScreen';
import CustomPagination from '../../components/CustomPagination';
import { IGenericEntry } from '../../interfaces/IGenericSection';
import ParentOrder from './components/ParentOrder';
import Order from './components/Order';
import { IOrder } from '../../interfaces/IOrder';

const searchOptions: IGenericEntry[] = [
  {
    code: 'orderId',
    value: 'Order ID'
  },
  {
    code: 'customerId',
    value: 'Customer ID'
  },
  {
    code: 'pickNumber',
    value: 'Pick number'
  }
];

const OrderManagement: React.FC<{
  history: any;
  permissions: any;
}> = ({ history, permissions }) => {
  const [orders, setOrders] = useState<IOrder[]>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const pageSize = 5;

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };
  console.log(permissions);

  return (
    <>
      <HeroBanner title='Order Management' background={HeroBalloons} />
      <SearchBar
        searchOptions={searchOptions}
        buttonTitle='Search for order'
        path='order'
        clearData={() => setOrders([])}
        handleSuccess={(dbOrders: IOrder[]) => setOrders(dbOrders)}
        history={history}
        location='/order-management'
      />
      {orders && (
        <Container fixed>
          {orders.length === 0 && (
            <NoResultsScreen message='Search by item SKU or item description' />
          )}
          {orders
            .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
            .map((order: IOrder) =>
              order.childrenCount > 0 ? (
                <ParentOrder
                  key={order.orderId}
                  order={order}
                  clearData={() => setOrders([])}
                  hideCustomerInfo={!permissions.viewCustomerInfo}
                />
              ) : (
                <Order
                  key={order.orderId}
                  order={order}
                  clearData={() => setOrders([])}
                  hideCustomerInfo={!permissions.viewCustomerInfo}
                />
              )
            )}
          {orders.length > pageSize && (
            <CustomPagination
              itemsCount={orders.length}
              pageSize={pageSize}
              handlePageChange={handlePageChange}
            />
          )}
        </Container>
      )}
    </>
  );
};

export default OrderManagement;
