import React from 'react';

import StyledInputLabel from './styles';

interface ILabelProps {
  id: string;
  control: JSX.Element;
  label: string;
  classes?: string;
}

const Label: React.SFC<ILabelProps> = ({ id, control, label, classes }) => (
  <StyledInputLabel htmlFor={id} className={classes}>
    {label}
    {control}
  </StyledInputLabel>
);

Label.defaultProps = {
  classes: ''
};

export default Label;
