import React from 'react';
import HeroHome from '../../assets/heros/hero-home.png';
import HeroBanner from '../../components/HeroBanner';

const Home: React.FC = () => (
  <HeroBanner
    title='Welcome to Cavendish!'
    background={HeroHome}
    classes='h-320 align-left'
  />
);

export default Home;
