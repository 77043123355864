import React from 'react';
import { Select, MenuItem } from '@material-ui/core';

import StyledFormControl from './styles';
import Label from '../Label';

interface IControlledSelectProps {
  id: string;
  label: string;
  value: any;
  options: any[];
  handleChange: any;
  classes?: string;
}

const ControlledSelect: React.SFC<IControlledSelectProps> = ({
  id,
  label,
  value,
  handleChange,
  options,
  classes
}) => (
  <Label
    id={id}
    control={
      <StyledFormControl variant={'outlined' as any}>
        <Select
          data-testid='select-testid'
          displayEmpty
          inputProps={{
            id,
            'data-testid': 'select-input-testid'
          }}
          value={value}
          onChange={handleChange}
        >
          {options.map((option: any) => (
            <MenuItem key={option.code} value={option}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    }
    label={label}
    classes={classes}
  />
);

ControlledSelect.defaultProps = {
  classes: ''
};

export default ControlledSelect;
