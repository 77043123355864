import styled from 'styled-components';
import { Dialog } from '@material-ui/core';

export default styled(Dialog)`
  .close-btn {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
  }

  #dialog-title h2 {
    font-size: 56px !important;
    line-height: 62px;
    font-family: Vodafone Lt;
    color: white;
    text-align: center;
  }

  h3 {
    font-size: 28px;
    margin: 0;
    margin-bottom: 35px;
    font-family: Vodafone Lt;
    color: white;
    text-align: center;
  }

  hr {
    color: ${(props) => props.theme.colors.white};
    width: 100%;
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .dialog-content__form {
      display: flex;
      flex-direction: column;

      h4 {
        font-size: 22px;
        color: white;
        margin: 0;
        margin-top: 10px;
      }

      .column {
        & > * {
          margin: 10px 0;
        }
      }
    }
  }
`;
