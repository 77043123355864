import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowRight from '../../../../assets/icons/arrow-right.svg';
import ArrowLeft from '../../../../assets/icons/arrow-left.svg';
import InnerAccordion from '../../../../components/InnerAccordion';
import DeliveryDetails from '../DeliveryDetails';
import OrderItemList from '../OrderItemList';
import { IDeliveryGroup } from '../../../../interfaces/IOrder';
import DeliveryPipeline from '../DeliveryPipeline';
import CustomButton from '../../../../components/CustomButton';
import DispatchModal from '../DispatchModal';

const DeliveryGroup: React.FC<{
  orderID: string;
  group: IDeliveryGroup;
  clearData: CallableFunction;
  returnReferenceCode?: string;
  hideCustomerInfo: boolean;
}> = ({ orderID, group, clearData, returnReferenceCode, hideCustomerInfo }) => {
  const [displayItems, setDisplayItems] = useState(true);
  const [deliveryGroup, setDeliveryGroup] = useState(group);
  const history = useHistory();

  const redirectPick = () => {
    clearData();
    history.push({
      pathname: '/order-management',
      search: `?pickNumber=${group.pickNumber}`
    });
  };

  return (
    <InnerAccordion
      key={group.number}
      title={`${group.number} - ${
        group.details.dispatchMethod
          ? group.details.dispatchMethodName
            ? `${group.details.dispatchMethodName}, ${group.details.serviceName}`
            : group.details.dispatchMethod
          : 'REVERSE'
      }`}
      description={{
        code: 'Status',
        value: deliveryGroup.statusDesc || deliveryGroup.status
      }}
    >
      {displayItems ? (
        <>
          <DeliveryDetails
            details={group.details}
            consignmentNumber={deliveryGroup.consignmentNumber}
            pickNumber={group.pickNumber}
            expectedDeliveryDate={group.expectedDeliveryDate}
            inboundSlid={group.inboundSlid}
            outboundSlid={group.outboundSlid}
            redirectPick={redirectPick}
            returnReferenceCode={returnReferenceCode}
            isInbound={group.isInbound}
            isTradeIn={group.isTradeIn}
            hideCustomerInfo={hideCustomerInfo}
          />
          <OrderItemList items={deliveryGroup.items} />
          <div className='row row--justify-space-between'>
            {deliveryGroup.status === 'DISPATCH_INITIATED' && (
              <DispatchModal
                orderID={orderID}
                deliveryGroup={deliveryGroup}
                setDeliveryGroup={(newGroup: any) => setDeliveryGroup(newGroup)}
              />
            )}
            <CustomButton
              title='Delivery pipeline'
              endIcon={<img src={ArrowRight} alt='Arrow right' />}
              handleClick={() => setDisplayItems(false)}
            />
          </div>
        </>
      ) : (
        <>
          <DeliveryPipeline history={deliveryGroup.history || []} />
          <div className='row'>
            <CustomButton
              title='Delivery details'
              classes='btn--grey'
              startIcon={<img src={ArrowLeft} alt='Arrow left' />}
              handleClick={() => setDisplayItems(true)}
            />
          </div>
        </>
      )}
    </InnerAccordion>
  );
};

DeliveryGroup.defaultProps = {
  returnReferenceCode: ''
};

export default DeliveryGroup;
