import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import fileDownload from 'js-file-download';

import { dateHourFormatter, boolFormatter } from '../../../../utils';
import CustomAccordion from '../../../../components/CustomAccordion';
import DeliveryGroupList from '../DeliveryGroupList';
import OrderSummary from '../OrderSummary';
import { IOrder, IValidationError } from '../../../../interfaces/IOrder';
import Error from '../../../../components/Error';
import GenericEntry from '../../../../components/GenericSection/components/GenericEntry';
import environment from '../../../../environment';
import CustomButton from '../../../../components/CustomButton';

const Order: React.SFC<{
  order: IOrder;
  clearData: CallableFunction;
  hideCustomerInfo: boolean;
}> = ({ order, clearData, hideCustomerInfo }) => {
  const downloadOrderPayload = () => {
    axios
      .get(
        `${environment.apiPath}downloadOrderPayload?orderId=${order.orderId}`,
        {
          responseType: 'text',
          ...environment.params
        }
      )
      .then(
        (res) => fileDownload(atob(res.data), `${order.orderId}.xml`),
        (err) => toast.error(err.message)
      );
  };

  return (
    <CustomAccordion
      key={order.orderId}
      status={order.status}
      primaryTitle={order.orderId}
      secondaryTitle={dateHourFormatter(order.dateTimeRaised)}
      summaryEntries={[
        {
          code: 'Origin',
          value: order.origin
        },
        {
          code: 'Parent order',
          value: order.parentOrderId ? order.parentOrderId : '-'
        },
        {
          code: 'Customer',
          value: order.customerEmail,
          hideCustomerInfo
        },
        {
          code: 'Legacy format',
          value: boolFormatter(order.isLegacyFormat)
        },
        {
          code: 'GRS Ref Number',
          value: order.grsRefNumber ? order.grsRefNumber : '-'
        }
      ]}
    >
      {order.status === 'REJECTED' ? (
        <>
          {order.validationErrors.map((err: IValidationError) => (
            <Error
              key={err.errorCode}
              title={err.errorCode}
              description={err.errorMessage}
            >
              {err.deliveryGroupNumber ? (
                <GenericEntry
                  code='Delivery group'
                  value={`No. ${err.deliveryGroupNumber}`}
                />
              ) : (
                ''
              )}
              {err.lineItemId && err.productCode ? (
                <GenericEntry
                  code='Line item'
                  value={`No. ${err.lineItemId} - ${err.productCode}`}
                />
              ) : (
                ''
              )}
            </Error>
          ))}
          <CustomButton
            title='Download payload'
            classes='btn--w-20'
            handleClick={() => downloadOrderPayload()}
          />
        </>
      ) : (
        <>
          <OrderSummary order={order} hideCustomerInfo={hideCustomerInfo} />
          <DeliveryGroupList
            orderID={order.orderId}
            deliveryGroups={order.deliveryGroups}
            clearData={clearData}
            returnReferenceCode={order.returnReferenceCode}
            hideCustomerInfo={hideCustomerInfo}
          />
        </>
      )}
    </CustomAccordion>
  );
};

export default Order;
