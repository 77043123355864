import React, { useState, useEffect } from 'react';
import { Router, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import 'ag-grid-enterprise';

import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';

import StyledLayout from './styles';
import cavendishTheme from './assets/theme';
import history from './services/history';
import Header from './components/Header';
import Footer from './components/Footer';
import OrderManagement from './pages/OrderManagement';
import InventoryManagement from './pages/InventoryManagement';
import Toast from './components/Toast';
import amplifyConfig from './config/amplifyConfig';
import { IAuthState } from './interfaces/IAuthState';
import mountHubListener, {
  getUser,
  getUserPermissions
} from './services/authListener';
import Reporting from './pages/Reporting';
import ScheduledReports from './pages/Reporting/components/ScheduledReports';
import StockAlignmentReporting from './pages/StockAlignmentReporting';
import HierarchyDeliveryMapping from './pages/Configuration/HierarchyDeliveryMapping';
import OriginSegmentMapping from './pages/Configuration/OriginSegmentMapping';
import ReturnBagOverrides from './pages/Configuration/ReturnBagOverrides';
import ProductPresetAddOns from './pages/Configuration/ProductPresetAddOns';
import StateChangeAddons from './pages/Configuration/StateChangeAddons';
import LowValueDeliveryOverride from './pages/Configuration/LowValueDeliveryOverride';
import Home from './pages/Home';
import SystemReporting from './pages/SystemReporting';
import HierarchyAddOns from './pages/Configuration/HierarchyAddOns';
import ReturnsReporting from './pages/ReturnsReporting';

Auth.configure(amplifyConfig);

const App: React.FC = () => {
  const [userState, setUserState] = useState<IAuthState>({
    authState: 'loading'
  });

  async function onLoad() {
    try {
      const user = await getUser();
      setUserState({
        authState: 'signedIn',
        user,
        permissions: getUserPermissions(user?.userData.attributes.profile)
      });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    mountHubListener(setUserState);
    onLoad();
  }, []);

  // Add idToken on every req, when Redux will be in place we can remove this
  axios.interceptors.request.use((request) => {
    if (userState.user) {
      request.headers.Authorization =
        userState.user?.userData?.signInUserSession?.idToken?.jwtToken;
    }

    return request;
  });
  console.log(userState);

  return (
    <ThemeProvider theme={cavendishTheme}>
      <Toast />
      <StyledLayout>
        <Router history={history}>
          <Header userState={userState} setUserState={setUserState} />
          <div className='page-content'>
            <Route exact path='/' component={Home} />
            {userState.authState === 'signedIn' && (
              <>
                <Route
                  path='/order-management'
                  render={(props) => (
                    <OrderManagement
                      {...props}
                      permissions={userState?.permissions}
                    />
                  )}
                />
                <Route
                  path='/inventory-management'
                  render={(props) => (
                    <InventoryManagement
                      {...props}
                      permissions={userState?.permissions}
                    />
                  )}
                />
                {userState.permissions?.viewOrderReports === true && (
                  <Route
                    path='/reporting'
                    render={({ match: { url } }) => (
                      <>
                        <Route
                          path={`${url}/order`}
                          component={Reporting}
                          exact
                        />
                        <Route
                          path={`${url}/returns`}
                          component={ReturnsReporting}
                          exact
                        />
                        <Route
                          path={`${url}/scheduled-reports`}
                          component={ScheduledReports}
                        />
                        <Route
                          path={`${url}/system`}
                          component={SystemReporting}
                          exact
                        />
                        <Route
                          path={`${url}/stock-alignment`}
                          component={StockAlignmentReporting}
                          exact
                        />
                      </>
                    )}
                  />
                )}
                {userState.permissions?.viewConfigInfo === true && (
                  <Route
                    path='/configuration'
                    render={({ match: { url } }) => (
                      <>
                        <Route
                          path={`${url}/hierarchy-delivery`}
                          component={HierarchyDeliveryMapping}
                        />
                        <Route
                          path={`${url}/origin-segment`}
                          component={OriginSegmentMapping}
                        />
                        <Route
                          path={`${url}/return-bag-overrides`}
                          component={ReturnBagOverrides}
                        />
                        <Route
                          path={`${url}/product-present-addons`}
                          component={ProductPresetAddOns}
                        />
                        <Route
                          path={`${url}/state-change-addons`}
                          component={StateChangeAddons}
                        />
                        <Route
                          path={`${url}/low-value-delivery-override`}
                          component={LowValueDeliveryOverride}
                        />
                        <Route
                          path={`${url}/hierarchy-addons`}
                          component={HierarchyAddOns}
                        />
                      </>
                    )}
                  />
                )}
              </>
            )}
          </div>
        </Router>
        <Footer />
      </StyledLayout>
    </ThemeProvider>
  );
};

export default App;
