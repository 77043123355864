import React, { useState, SyntheticEvent } from 'react';
import {
  DialogTitle,
  DialogContent,
  Container,
  IconButton
} from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';

import CustomButton from '../../../../components/CustomButton';
import cavendishTheme from '../../../../assets/theme';
import CloseIcon from '../../../../assets/icons/close.svg';
import StyledDialog from './styles';
import ControlledInput from '../../../../components/ControlledInput';
import environment from '../../../../environment';
import LoadingScreen from '../../../../components/LoadigScreen';
import { IGenericEntry } from '../../../../interfaces/IGenericSection';
import GenericEntry from '../../../../components/GenericSection/components/GenericEntry';

interface ISearchQueryProps {
  productCodes: string[];
  productDescription: string;
  stockPot: string;
  statusOperator: IGenericEntry;
  status: string;
  startDate: Date | null;
  endDate: Date | null;
  reportQueries: any[];
  setReportQueries: CallableFunction;
}

const SearchQueryModal: React.FC<ISearchQueryProps> = ({
  productCodes,
  productDescription,
  stockPot,
  statusOperator,
  status,
  startDate,
  endDate,
  reportQueries,
  setReportQueries
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [queryName, setQueryName] = useState('');

  const handleResponse = () => {
    setQueryName('');
    setOpen(false);
    setLoading(false);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);

    axios
      .post(
        `${environment.apiPath}reportQuery`,
        {
          queryName,
          queryData: {
            productCodes,
            descriptions: [productDescription],
            stockPot,
            status,
            statusOperator,
            createDateStart: startDate,
            createDateEnd: endDate
          }
        },
        { ...environment.params }
      )
      .then(
        (res) => {
          toast.success(`Query ${queryName} successfully saved`);
          handleResponse();
          setReportQueries([...reportQueries, res.data]);
        },
        (err) => {
          toast.error(err.message);
          handleResponse();
        }
      );
  };

  const getCreateDateValue = (): string => {
    let createDate: string = startDate
      ? moment(startDate).format('DD/MM/YYYY')
      : '';
    if (endDate) {
      if (createDate) {
        createDate = createDate.concat(' - ');
      }
      createDate = createDate.concat(moment(endDate).format('DD/MM/YYYY'));
    }

    return createDate;
  };

  return (
    <>
      <CustomButton
        type='button'
        classes='btn--w-200-px btn--blue'
        title='Save'
        handleClick={() => setOpen(true)}
      />

      <StyledDialog
        fullScreen
        open={open}
        aria-labelledby='dialog-title'
        PaperProps={{
          style: {
            backgroundColor: cavendishTheme.colors.black
          }
        }}
      >
        <Container fixed className='close-btn'>
          <IconButton
            tabIndex={0}
            aria-label='Close'
            onClick={() => setOpen(false)}
            className='close'
          >
            <img src={CloseIcon} alt='Close' />
          </IconButton>
        </Container>

        <DialogTitle id='dialog-title'>Save Reporting Query</DialogTitle>
        <div className='search-query-params'>
          {productCodes.length > 0 && (
            <GenericEntry
              key='product-codes'
              code='Item SKUs'
              value={productCodes.join(', ')}
            />
          )}
          {productDescription && (
            <GenericEntry
              key='description'
              code='Item description'
              value={productDescription}
            />
          )}
          {stockPot && (
            <GenericEntry key='stock-pot' code='Stock pot' value={stockPot} />
          )}
          {status && (
            <GenericEntry
              key='status'
              code='Status'
              value={`(${statusOperator.value}) ${status}`}
            />
          )}
          {(startDate || endDate) && (
            <GenericEntry
              key='create-date'
              code='Create date'
              value={getCreateDateValue()}
            />
          )}
          <hr />
        </div>
        <DialogContent className='dialog-content'>
          <form
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
            className='dialog-content__form'
          >
            <ControlledInput
              id='query'
              label='Query name'
              type='text'
              value={queryName}
              placeholder='Type search query name here'
              handleChange={(event: any) => setQueryName(event.target.value)}
            />
            <CustomButton title='Save' type='submit' classes='btn--w-100' />
          </form>
        </DialogContent>
        {loading && <LoadingScreen />}
      </StyledDialog>
    </>
  );
};

export default SearchQueryModal;
