import React, { useState, ChangeEvent } from 'react';
import { Container } from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';

import HeroBalloons from '../../assets/heros/hero-balloons.png';
import ArrowLeft from '../../assets/icons/arrow-left.svg';
import ArrowRight from '../../assets/icons/arrow-right.svg';
import OrganizationIcon from '../../assets/icons/organization.svg';
import HeroBanner from '../../components/HeroBanner';
import SearchBar from '../../components/SearchBar';
import CustomAccordion from '../../components/CustomAccordion';
import CustomButton from '../../components/CustomButton';
import Error from '../../components/Error';
import { IGenericEntry } from '../../interfaces/IGenericSection';
import ItemSummary from './components/ItemSummary';
import ChannelList from './components/ChannelList';
import {
  IInventoryData,
  IInventoryItem
} from '../../interfaces/IInventoryItem';
import NoResultsScreen from '../../components/NoResultsScreen';
import CustomPagination from '../../components/CustomPagination';
import AuditTrail from './components/AuditTrail';
import GenericTitle from '../../components/GenericSection/components/GenericTitle';
import environment from '../../environment';
import LoadingScreen from '../../components/LoadigScreen';

const searchOptions: IGenericEntry[] = [
  {
    code: 'productCode',
    value: 'Item SKU'
  },
  {
    code: 'description',
    value: 'Item description'
  }
];

const initialInventory: IInventoryData = {
  count: -1,
  allItems: true,
  items: []
};

const InventoryManagement: React.FC<{
  history: any;
  permissions: any;
}> = ({ history, permissions }) => {
  const [contentIdx, setContentIdx] = useState(1);
  const [inventoryData, setInventoryData] =
    useState<IInventoryData>(initialInventory);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const pageSize = 5;

  const handleSuccess = (data: IInventoryData) => {
    setPageNumber(1);
    setInventoryData(data);
  };

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  const handleContentChange = (idx: number) => {
    setContentIdx(idx);
  };

  const clearInventory = () => {
    setInventoryData(initialInventory);
    setContentIdx(1);
  };

  const loadChannels = (it: IInventoryItem) => {
    setLoading(true);

    axios
      .get(`${environment.apiPath}product/${it.productCode}`, {
        ...environment.params
      })
      .then(
        (res) => {
          setInventoryData({
            ...inventoryData,
            items: inventoryData.items.map((item: IInventoryItem) =>
              item.productCode === it.productCode
                ? {
                    ...item,
                    channels: res.data.channels
                  }
                : item
            )
          });
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          toast.error(err.message);
        }
      );
  };

  const renderDetails = (item: IInventoryItem) => {
    switch (contentIdx) {
      case 1:
        return (
          <>
            <ItemSummary item={item} />
            <GenericTitle
              img={OrganizationIcon}
              altText='Organization'
              title='Organization channels'
            />
            {(!item.channels || item.channels.length === 0) && (
              <CustomButton
                title='Load'
                classes='btn--blue btn--w-10'
                handleClick={() => loadChannels(item)}
              />
            )}
            {item.channels && item.channels.length > 0 && (
              <ChannelList
                productCode={item.productCode}
                productDescription={item.description}
                productIsVirtual={item.isVirtual}
                channels={item.channels}
                inventoryData={inventoryData}
                setInventoryData={setInventoryData}
                isDeleted={item.deleted}
                allowStockChange={permissions.changeStockLevel}
              />
            )}
            <div className='row row--justify-end'>
              <CustomButton
                title='Audit trail'
                endIcon={<img src={ArrowRight} alt='Arrow right' />}
                handleClick={() => handleContentChange(2)}
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <AuditTrail productCode={item.productCode} />
            <div className='row'>
              <CustomButton
                title='Item details'
                classes='btn--grey'
                startIcon={<img src={ArrowLeft} alt='Arrow left' />}
                handleClick={() => handleContentChange(1)}
              />
            </div>
          </>
        );
      default:
        return (
          <div className='row row--justify-end'>
            <CustomButton
              title='Item details'
              endIcon={<img src={ArrowRight} alt='Arrow right' />}
              handleClick={() => handleContentChange(1)}
            />
          </div>
        );
    }
  };

  return (
    <>
      <HeroBanner title='Inventory Management' background={HeroBalloons} />
      <SearchBar
        searchOptions={searchOptions}
        buttonTitle='Search for item'
        path='product'
        clearData={clearInventory}
        handleSuccess={(data: IInventoryData) => handleSuccess(data)}
        history={history}
        location='inventory-management'
      />

      <Container fixed>
        {!inventoryData.allItems && (
          <Error
            title='Too many items were found for this search!'
            description='If you want to see more results, try your search again with more specific keywords.'
          />
        )}
        {inventoryData.count === 0 && (
          <NoResultsScreen message='Search by item SKU or item description' />
        )}

        {inventoryData.items
          .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
          .map((item: IInventoryItem) => (
            <CustomAccordion
              key={item.productCode}
              status={item.status}
              isDeleted={item.deleted}
              primaryTitle={item.productCode}
              secondaryTitle={item.description}
              summaryEntries={[
                {
                  code: 'Item category',
                  value: item.category
                },
                {
                  code: 'Item type',
                  value: item.productType
                },
                {
                  code: 'Last updated',
                  value: '20 August 2020'
                }
              ]}
            >
              {renderDetails(item)}
            </CustomAccordion>
          ))}

        {inventoryData.count > pageSize && (
          <CustomPagination
            itemsCount={inventoryData.count}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
          />
        )}
      </Container>
      {loading && <LoadingScreen />}
    </>
  );
};

export default InventoryManagement;
