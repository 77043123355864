import React, { useState, SyntheticEvent } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import HeroBalloons from '../../assets/heros/hero-balloons.png';
import HeroBanner from '../../components/HeroBanner';
import ReturnsSearchMenu from './components/ReturnsSearchMenu/ReturnsSearchMenu';
import environment from '../../environment';
import { IGenericEntry } from '../../interfaces/IGenericSection';
import StyledReport from './styles';
import ControlledInput from '../../components/ControlledInput';
import CustomButton from '../../components/CustomButton';
import DownloadIcon from '../../assets/icons/download.svg';

const ReturnsReporting: React.FC = () => {
  const [rowData, setRowData] = useState([]);
  const [productCode, setProductCode] = useState<string>('');
  const [productCodes, setProductCodes] = useState<string[]>([]);
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [itemType, setItemType] = useState<string>('');
  const [returnTypes] = useState<IGenericEntry[]>([
    {
      code: '',
      value: 'ALL'
    },
    {
      code: 'Unexpected/Unknown Return',
      value: 'UNEXPECTED'
    },
    {
      code: 'Failed Delivery',
      value: 'FAILED DELIVERY'
    },
    {
      code: 'Customer Return',
      value: 'CUSTOMER RETURN'
    }
  ]);
  const [returnType, setReturnType] = useState<IGenericEntry>(returnTypes[0]);
  const [createDateOptions] = useState<IGenericEntry[]>([
    {
      code: 'customDate',
      value: 'Custom date'
    }
  ]);
  const [createDateOption, setCreateDateOption] = useState<IGenericEntry>(
    createDateOptions[0]
  );
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [gridApi, setGridApi] = useState<any>();
  const [quickFilter, setQuickFilter] = useState<string>('');

  const getRowData = () => {
    let rows: any = [];
    let requestsWaiting = 1;

    const queryData = (executionId = '', nextOffset = '', pageSize = '') => {
      axios
        .get(
          `${
            environment.apiPath
          }getReturnsReportItems?queryExecutionId=${executionId}&nextOffset=${nextOffset}&pageSize=${pageSize}&productCodes=${productCodes.join(
            ','
          )}&serialNumber=${serialNumber}&itemType=${itemType}&returnType=${
            returnType.code
          }&createDateStart=${
            startDate ? moment(startDate).format('YYYY-MM-DD') : ''
          }&createDateEnd=${
            endDate ? moment(endDate).format('YYYY-MM-DD') : ''
          }`,
          {
            ...environment.params
          }
        )
        .then(
          (res) => {
            rows = rows.concat(
              res.data.rows.map((row: any[]) =>
                _.zipObject(res.data.columns, row)
              )
            );

            if (res.data.nextOffsets.length > 0) {
              requestsWaiting = res.data.nextOffsets.length;
              res.data.nextOffsets.map((token: any) =>
                queryData(
                  res.data.queryExecutionId,
                  token.offset,
                  token.pageSize
                )
              );
            } else {
              requestsWaiting -= 1;
            }

            if (requestsWaiting === 0) {
              rows.forEach((row: any) => {
                if (row.orderDate && row.orderTime) {
                  const orderDate = moment(row.orderDate, 'DD/MM/YYYY').format(
                    'MM/DD/YYYY'
                  );
                  const date = new Date(`${orderDate} ${row.orderTime} UTC`);
                  row.orderDate = moment(date).format('DD/MM/YYYY');
                  row.orderTime = moment(date).format('HH:mm:ss');
                }
              });
              setRowData(rows);
            }

            if (res.data.rows.length === 0) {
              gridApi.showNoRowsOverlay();
            }
          },
          (err) => {
            setRowData([]);
            gridApi.showNoRowsOverlay();
            toast.error(err.message);
          }
        );
    };

    queryData();
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    gridApi?.showLoadingOverlay();
    getRowData();
  };

  const handleDataExport = () => {
    gridApi?.exportDataAsCsv();
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  return (
    <>
      <HeroBanner title='Returns Management' background={HeroBalloons} />
      <ReturnsSearchMenu
        serialNumber={serialNumber}
        setSerialNumber={setSerialNumber}
        itemType={itemType}
        setItemType={setItemType}
        returnType={returnType}
        setReturnType={setReturnType}
        returnTypes={returnTypes}
        handleSubmit={handleSubmit}
        createDateOption={createDateOption}
        setCreateDateOption={setCreateDateOption}
        createDateOptions={createDateOptions}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        productCode={productCode}
        setProductCode={setProductCode}
        productCodes={productCodes}
        setProductCodes={setProductCodes}
      />
      <StyledReport fixed>
        <div className='report__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: any) => {
              setQuickFilter(event.target.value);
              gridApi?.setQuickFilter(event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <CustomButton
            type='button'
            classes='btn--w-200-px btn--black'
            title='Export'
            endIcon={<img src={DownloadIcon} alt='Download report' />}
            handleClick={handleDataExport}
          />
        </div>
        <div className='report__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 200,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            suppressRowClickSelection
            pagination
            rowData={rowData}
            onGridReady={onGridReady}
            rowGroupPanelShow='always'
            suppressDragLeaveHidesColumns
          >
            <AgGridColumn headerName='Item' groupId='itemGroup'>
              <AgGridColumn
                headerName='SKU'
                field='sku'
                minWidth={100}
                enableRowGroup
              />
              <AgGridColumn
                headerName='Serial Number'
                field='serialNumber'
                minWidth={150}
              />
              <AgGridColumn
                headerName='Item Type'
                field='producType'
                minWidth={150}
                enableRowGroup
              />
              <AgGridColumn field='condition' minWidth={100} enableRowGroup />
              <AgGridColumn
                field='description'
                minWidth={300}
                columnGroupShow='open'
              />
            </AgGridColumn>
            <AgGridColumn headerName='Return Order' groupId='orderGroup'>
              <AgGridColumn headerName='Order ID' field='orderId' />
              <AgGridColumn
                headerName='Return Date'
                field='orderDate'
                sortable
                minWidth={150}
                filter='agDateColumnFilter'
                filterParams={{
                  comparator: (
                    filterLocalDateAtMidnight: Date,
                    cellValue: string
                  ) => {
                    const dateAsString = cellValue;
                    const dateParts = dateAsString.split('/');
                    const cellDate = new Date(
                      Number(dateParts[2]),
                      Number(dateParts[1]) - 1,
                      Number(dateParts[0])
                    );

                    if (
                      filterLocalDateAtMidnight.getTime() === cellDate.getTime()
                    ) {
                      return 0;
                    }

                    if (cellDate < filterLocalDateAtMidnight) {
                      return -1;
                    }

                    return 1;
                  }
                }}
              />
              <AgGridColumn
                headerName='Return Time'
                field='orderTime'
                sortable
                minWidth={150}
                columnGroupShow='open'
              />
              <AgGridColumn field='origin' columnGroupShow='open' />
              <AgGridColumn field='segment' columnGroupShow='open' />
              <AgGridColumn
                headerName='Return Method'
                field='deliveryMethod'
                columnGroupShow='open'
              />
              <AgGridColumn
                headerName='Consignment Number'
                field='consignmentNumber'
                columnGroupShow='open'
              />
              <AgGridColumn
                headerName='Return Type'
                field='returnType'
                columnGroupShow='open'
                enableRowGroup
              />
              <AgGridColumn
                headerName='Acq/Ret'
                field='acquisition'
                columnGroupShow='open'
                enableRowGroup
              />
              <AgGridColumn
                headerName='Original Order ID'
                field='originalOrderId'
                columnGroupShow='open'
              />
              <AgGridColumn
                headerName='Pre-alert ID'
                field='preAlertId'
                columnGroupShow='open'
              />
              <AgGridColumn
                headerName='Pre-alert Date'
                field='preAlertDate'
                columnGroupShow='open'
              />
              <AgGridColumn
                headerName='Pre-alert Time'
                field='preAlertDate'
                columnGroupShow='open'
              />
            </AgGridColumn>
            <AgGridColumn headerName='Customer' groupId='customerGroup'>
              <AgGridColumn headerName='Account' field='account' />
              <AgGridColumn
                headerName='Invoice Name'
                field='invoiceName'
                columnGroupShow='open'
              />
              <AgGridColumn
                headerName='CTN'
                field='ctn'
                columnGroupShow='open'
              />
            </AgGridColumn>
          </AgGridReact>
        </div>
      </StyledReport>
    </>
  );
};

export default ReturnsReporting;
