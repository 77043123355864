import React, { useState } from 'react';
import { Chip, Container } from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Alert } from '@material-ui/lab';

import StyledSearchMenu from './styles';
import ControlledInput from '../../../../components/ControlledInput';
import CustomButton from '../../../../components/CustomButton';
import { IGenericEntry } from '../../../../interfaces/IGenericSection';
import ControlledSelect from '../../../../components/ControlledSelect';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import environment from '../../../../environment';
import { IInventoryItem } from '../../../../interfaces/IInventoryItem';

interface ISearchMenuProps {
  productCodes: string[];
  setProductCodes: CallableFunction;
  serialNumber: string;
  setSerialNumber: CallableFunction;
  itemType: string;
  setItemType: CallableFunction;
  returnType: IGenericEntry;
  setReturnType: CallableFunction;
  returnTypes: IGenericEntry[];
  createDateOption: IGenericEntry;
  setCreateDateOption: CallableFunction;
  createDateOptions: IGenericEntry[];
  startDate: Date | null;
  setStartDate: CallableFunction;
  endDate: Date | null;
  setEndDate: CallableFunction;
  handleSubmit: any;
  productCode: string;
  setProductCode: CallableFunction;
}

const ReturnsSearchMenu: React.FC<ISearchMenuProps> = ({
  productCodes,
  setProductCodes,
  serialNumber,
  setSerialNumber,
  itemType,
  setItemType,
  returnType,
  setReturnType,
  returnTypes,
  createDateOption,
  setCreateDateOption,
  createDateOptions,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  handleSubmit,
  productCode,
  setProductCode
}) => {
  const [errorCodes, setErrorCodes] = useState<string[]>([]);

  const addProductCode = () => {
    const newProducts = productCode.split(',');
    axios
      .get(`${environment.apiPath}product?productCode=${productCode}`, {
        ...environment.params
      })
      .then(
        (res) => {
          const codes = res.data.items.map(
            (product: IInventoryItem) => product.productCode
          );
          const errCodes = newProducts.filter(
            (product: string) => !codes.includes(product.replace(/\s/g, ''))
          );
          setProductCodes([
            ...productCodes,
            ...codes.filter(
              (product: string) => !productCodes.includes(product)
            )
          ]);
          setErrorCodes(errCodes);
          setProductCode('');
        },
        (err) => toast.error(err.message)
      );
  };

  const removeProductCode = (product: string) => {
    setProductCodes(productCodes.filter((item) => item !== product));
  };

  const clearSearchOptions = () => {
    setProductCodes([]);
    setErrorCodes([]);
    setSerialNumber('');
    setItemType('');
    setReturnType(returnTypes[0]);
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <StyledSearchMenu>
      <Container fixed>
        <h2>Search Returns</h2>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <div className='search-menu-row'>
            <div className='product-code-search'>
              <div className='product-code-search__input'>
                <ControlledInput
                  id='product-code'
                  placeholder='Type item SKU here'
                  value={productCode}
                  handleChange={(event: any) =>
                    setProductCode(event.target.value)
                  }
                  label='Item SKU'
                  type='text'
                  classes='label--w-100'
                />
                <CustomButton
                  type='button'
                  classes='btn--w-10 btn--blue'
                  title='Add'
                  handleClick={() => addProductCode()}
                />
              </div>
              {errorCodes.length > 0 && (
                <Alert severity='error'>
                  Product(s) {errorCodes.join(', ')} not found
                </Alert>
              )}
              {productCodes.map((product: string) => (
                <Chip
                  key={product}
                  label={product}
                  onDelete={() => removeProductCode(product)}
                />
              ))}
            </div>
          </div>
          <hr />
          <div className='search-menu-row'>
            <ControlledInput
              id='serial-number'
              placeholder='Type serial number here'
              value={serialNumber}
              handleChange={(event: any) => setSerialNumber(event.target.value)}
              label='Serial number'
              type='text'
              classes='label--w-30'
            />
            <ControlledInput
              id='item-type'
              placeholder='Type item type here'
              value={itemType}
              handleChange={(event: any) => setItemType(event.target.value)}
              label='Item type'
              type='text'
              classes='label--w-30'
            />
            <ControlledSelect
              id='return-type'
              value={returnType}
              handleChange={(event: any) => setReturnType(event.target.value)}
              label='Return type'
              options={returnTypes}
              classes='label--w-30'
            />
          </div>
          <hr />
          <div className='search-menu-row'>
            <ControlledSelect
              id='create-date-option'
              value={createDateOption}
              handleChange={(event: any) =>
                setCreateDateOption(event.target.value)
              }
              label='Return date'
              options={createDateOptions}
              classes='label--w-30'
            />
            <CustomDatePicker
              id='start-date'
              value={startDate}
              handleChange={(event: any) => setStartDate(event)}
              label='Start date'
              classes='label--w-30'
            />
            <CustomDatePicker
              id='end-date'
              value={endDate}
              handleChange={(event: any) => setEndDate(event)}
              label='End date'
              classes='label--w-30'
            />
          </div>
          <hr />
          <div className='search-menu-row'>
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--light-grey clear-btn'
              title='Clear'
              handleClick={() => clearSearchOptions()}
            />
            <CustomButton
              type='submit'
              classes='btn--w-200-px'
              title='Search'
            />
          </div>
        </form>
      </Container>
    </StyledSearchMenu>
  );
};

export default ReturnsSearchMenu;
