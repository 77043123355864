import React, { useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import axios from 'axios';

import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import HeroBanner from '../../../../components/HeroBanner';
import ScheduleReportModal from '../ScheduleReportModal';
import HeroBalloons from '../../../../assets/heros/hero-balloons.png';
import CustomButton from '../../../../components/CustomButton';
import ArrowRight from '../../../../assets/icons/arrow-right.svg';
import StyledScheduledReports from './styles';
import environment from '../../../../environment';
import { dateHourFormatter, dateHourFormatterReport } from '../../../../utils';
import EnableButton from './EnableButton';
import DownloadButton from './DownloadButton';
import ControlledInput from '../../../../components/ControlledInput';

const ScheduledReports: React.FC = () => {
  const history = useHistory();

  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState<any>();
  const [quickFilter, setQuickFilter] = useState<string>('');

  const getRowData = (gridApiParam: any) => {
    axios
      .get(`${environment.apiPath}getScheduledReports`, {
        ...environment.params
      })
      .then(
        (res) => {
          setRowData(res.data);
          if (res.data.length === 0) {
            gridApiParam?.showNoRowsOverlay();
          }
        },
        (err) => {
          setRowData([]);
          toast.error(err.message);
          gridApiParam?.showNoRowsOverlay();
        }
      );
  };

  const onGridReady = (params: any) => {
    params.api?.showLoadingOverlay();
    setGridApi(params.api);
    getRowData(params.api);
  };

  return (
    <>
      <HeroBanner title='Reporting' background={HeroBalloons} />
      <StyledScheduledReports fixed>
        <div className='reporting__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: any) => {
              setQuickFilter(event.target.value);
              gridApi?.setQuickFilter(event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <div>
            <ScheduleReportModal rowData={rowData} setRowData={setRowData} />
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--grey btn--ml-10'
              title='Reporting'
              endIcon={
                <img src={ArrowRight} alt='Navigate to reporting page' />
              }
              handleClick={() => history.push('/reporting/order')}
            />
          </div>
        </div>
        <div className='reporting__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 100,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            suppressRowClickSelection
            pagination
            rowData={rowData}
            onGridReady={onGridReady}
            rowGroupPanelShow='always'
            masterDetail
            frameworkComponents={{
              enableButton: EnableButton
            }}
            rowClassRules={{
              'disabled-row': (params) =>
                params.node.group ? false : !params.data.enabled
            }}
            gridOptions={{
              detailRowHeight: 255,
              isRowMaster: (dataItem) =>
                dataItem && dataItem.generatedFiles.length > 0
            }}
            detailCellRendererParams={{
              detailGridOptions: {
                rowHeight: 30,
                suppressRowClickSelection: true,
                frameworkComponents: {
                  downloadButton: DownloadButton
                },
                pagination: true,
                paginationPageSize: 5,
                columnDefs: [
                  { field: 'filename' },
                  {
                    field: 'createdDate',
                    valueGetter: (params: any) =>
                      dateHourFormatterReport(params.data.createdDate)
                  },
                  {
                    field: 'action',
                    cellRenderer: 'downloadButton',
                    maxWidth: 100
                  }
                ],
                defaultColDef: {
                  sortable: true,
                  resizable: true,
                  flex: 1
                }
              },
              getDetailRowData: (params: any) => {
                params.successCallback(params.data.generatedFiles);
              }
            }}
          >
            <AgGridColumn
              field='reportName'
              minWidth={300}
              cellRenderer='agGroupCellRenderer'
            />
            <AgGridColumn
              field='frequency'
              valueGetter={(params) =>
                params.node && params.node.group
                  ? ''
                  : params.data.frequency.charAt(0).toUpperCase() +
                    params.data.frequency.slice(1)
              }
            />
            <AgGridColumn
              field='startDate'
              valueGetter={(params) =>
                params.node && params.node.group
                  ? ''
                  : dateHourFormatter(params.data.startDate)
              }
            />
            <AgGridColumn
              field='endDate'
              valueGetter={(params) =>
                params.node && params.node.group
                  ? ''
                  : dateHourFormatter(params.data.endDate)
              }
            />
            <AgGridColumn
              field='enabled'
              headerName='Status'
              valueGetter={(params) =>
                params.node && params.node.group
                  ? ''
                  : params.data.enabled
                  ? 'Enabled'
                  : 'Disabled'
              }
              enableRowGroup
            />
            <AgGridColumn
              field='action'
              cellRendererSelector={(params: any) =>
                params.node && params.node.group
                  ? { component: '' }
                  : { component: 'enableButton' }
              }
            />
          </AgGridReact>
        </div>
      </StyledScheduledReports>
    </>
  );
};

export default ScheduledReports;
