import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { LicenseManager } from '@ag-grid-enterprise/core';

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

LicenseManager.setLicenseKey(
  'CompanyName=Vodafone,LicensedApplication=Cavendish,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-018064,ExpiryDate=13_August_2022_[v2]_MTY2MDM0NTIwMDAwMA==6668de1ea9276f841179cb6f01ac6480'
);

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
