import { Container } from '@material-ui/core';
import styled from 'styled-components';

export default styled(Container)`
  padding-bottom: 30px;

  &.MuiContainer-root {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    font-family: Vodafone Lt;
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.black};
    font-size: 36px;
  }

  h3 {
    font-family: Vodafone Lt;
    color: ${(props) => props.theme.colors.black};
    font-size: 24px;
    margin-top: 20px;
  }

  .row {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .chart {
    height: 450px;
    width: 500px;
    border: 2px ${(props) => props.theme.colors.lightGrey} solid;
  }

  .catalogue-reporting {
    justify-content: space-between;
  }

  .accordion__details--display-column {
    flex-direction: column;
  }
`;
