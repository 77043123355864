import React from 'react';
import { Container } from '@material-ui/core';

import StyledPageTitle from './styles';

export interface IPageTitleProps {
  pageTitle: string;
}

const PageTitle: React.FC<IPageTitleProps> = ({ pageTitle }) => (
  <StyledPageTitle>
    <Container>
      <h2>{pageTitle}</h2>
    </Container>
  </StyledPageTitle>
);

export default PageTitle;
